import axios from 'axios';

import buildPaginationQueryOpts from '@/shared/sort/sorts';

import { IPaperType } from '@/shared/model/paper-type.model';

const baseApiUrl = 'api/paper-types';

const paperTypesByCompanyUrl = 'api/paper-types-by-company';

export default class PaperTypeService {
  exportToCSVByCompany(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${paperTypesByCompanyUrl}/export-csv`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  createByCompany(entity: IPaperType): Promise<IPaperType> {
    return new Promise<IPaperType>((resolve, reject) => {
      axios
        .post(`${paperTypesByCompanyUrl}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  updateByCompany(entity: IPaperType): Promise<IPaperType> {
    return new Promise<IPaperType>((resolve, reject) => {
      axios
        .put(`${paperTypesByCompanyUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  findByCompany(id: number): Promise<IPaperType> {
    return new Promise<IPaperType>((resolve, reject) => {
      axios
        .get(`${paperTypesByCompanyUrl}/${id}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public uploadFile(file: File): Promise<any> {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('file', file);
      axios
        .post(paperTypesByCompanyUrl + '/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  public find(id: number): Promise<IPaperType> {
    return new Promise<IPaperType>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieve(paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl + `?${buildPaginationQueryOpts(paginationQuery)}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveByCompany(paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(paperTypesByCompanyUrl + `?${buildPaginationQueryOpts(paginationQuery)}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveAll(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl + `?unpaged=true`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveAllByCompany(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(paperTypesByCompanyUrl + `?unpaged=true`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public delete(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public create(entity: IPaperType): Promise<IPaperType> {
    return new Promise<IPaperType>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public update(entity: IPaperType): Promise<IPaperType> {
    return new Promise<IPaperType>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public partialUpdate(entity: IPaperType): Promise<IPaperType> {
    return new Promise<IPaperType>((resolve, reject) => {
      axios
        .patch(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
