var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      !_vm.inProduction ? _c("ribbon") : _vm._e(),
      _vm._v(" "),
      _c("jhi-navbar"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "container-fluid" },
        [
          _c("div", { staticClass: "row vh-100" }, [
            _vm.authenticated
              ? _c(
                  "nav",
                  {
                    staticClass:
                      "col-md-3 col-lg-2 d-md-block bg-light sidebar collapse",
                    attrs: { id: "sidebarMenu" },
                  },
                  [
                    _c("div", { staticClass: "sidebar-sticky pt-3" }, [
                      _c(
                        "ul",
                        { staticClass: "nav flex-column" },
                        [
                          _c(
                            "b-nav-item",
                            {
                              attrs: {
                                to: { path: "/" },
                                "data-cy": "dashboard-nav-item",
                                "exact-active-class": "active",
                              },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "fa-solid fa-home" },
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "p-1" }, [
                                _vm._v("Dashboard"),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.hasAnyAuthority("GROUP_ADMIN")
                            ? [
                                _c(
                                  "b-nav-item",
                                  {
                                    attrs: {
                                      to: { path: "/admin/quote" },
                                      "data-cy": "admin-quote-nav-item",
                                      "exact-active-class": "active",
                                    },
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      attrs: {
                                        icon: "fa-solid fa-file-invoice-dollar",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "p-1" }, [
                                      _vm._v("Quotation"),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-nav-item",
                                  {
                                    attrs: {
                                      to: { path: "/admin/machine" },
                                      "data-cy": "admin-machine-nav-item",
                                      "exact-active-class": "active",
                                    },
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      attrs: { icon: "cog" },
                                    }),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "p-1" }, [
                                      _vm._v("Machine"),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-nav-item",
                                  {
                                    attrs: {
                                      to: { path: "/admin/product-category" },
                                      "data-cy":
                                        "admin-product-category-nav-item",
                                      "exact-active-class": "active",
                                    },
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      attrs: { icon: "list" },
                                    }),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "p-1" }, [
                                      _vm._v("Product Category"),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-nav-item",
                                  {
                                    attrs: {
                                      to: { path: "/admin/product" },
                                      "data-cy": "admin-product-nav-item",
                                      "exact-active-class": "active",
                                    },
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      attrs: { icon: "box" },
                                    }),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "p-1" }, [
                                      _vm._v("Product"),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-nav-item",
                                  {
                                    attrs: {
                                      to: { path: "/admin/paper-category" },
                                      "data-cy":
                                        "admin-paper-category-nav-item",
                                      "exact-active-class": "active",
                                    },
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      attrs: { icon: "clipboard-list" },
                                    }),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "p-1" }, [
                                      _vm._v("Paper Category"),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-nav-item",
                                  {
                                    attrs: {
                                      to: { path: "/admin/paper-type" },
                                      "data-cy": "admin-paper-type-nav-item",
                                      "exact-active-class": "active",
                                    },
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      attrs: { icon: "file" },
                                    }),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "p-1" }, [
                                      _vm._v("Paper Types"),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-nav-item",
                                  {
                                    attrs: {
                                      to: { path: "/admin/paper-grammage" },
                                      "data-cy":
                                        "admin-paper-grammage-nav-item",
                                      "exact-active-class": "active",
                                    },
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      attrs: { icon: "weight" },
                                    }),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "p-1" }, [
                                      _vm._v("Paper Grammage"),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-nav-item",
                                  {
                                    attrs: {
                                      to: { path: "/admin/plate-color" },
                                      "data-cy": "admin-plate-color-nav-item",
                                      "exact-active-class": "active",
                                    },
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      attrs: { icon: "palette" },
                                    }),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "p-1" }, [
                                      _vm._v("Plate Colors"),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-nav-item",
                                  {
                                    attrs: {
                                      to: { path: "/admin/finishing-property" },
                                      "data-cy":
                                        "admin-finishing-property-nav-item",
                                      "exact-active-class": "active",
                                    },
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      attrs: {
                                        icon: "fa-solid fa-paint-roller",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "p-1" }, [
                                      _vm._v("Finishing Properties"),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-nav-item",
                                  {
                                    attrs: {
                                      to: { path: "/admin/finishing-type" },
                                      "data-cy":
                                        "admin-finishing-type-nav-item",
                                      "exact-active-class": "active",
                                    },
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      attrs: {
                                        icon: "fa-solid fa-paint-roller",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "p-1" }, [
                                      _vm._v("Finishing Type"),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-nav-item",
                                  {
                                    attrs: {
                                      to: { path: "/admin/pricing" },
                                      "data-cy": "admin-pricing-nav-item",
                                      "exact-active-class": "active",
                                    },
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      attrs: { icon: "fa-solid fa-tags" },
                                    }),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "p-1" }, [
                                      _vm._v("Finishing Pricing"),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-nav-item",
                                  {
                                    attrs: {
                                      to: { path: "/admin/company" },
                                      "data-cy": "admin-company-nav-item",
                                      "exact-active-class": "active",
                                    },
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      attrs: { icon: "fa-solid fa-building" },
                                    }),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "p-1" }, [
                                      _vm._v("Companies"),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-nav-item",
                                  {
                                    attrs: {
                                      to: { path: "/admin/company-category" },
                                      "data-cy":
                                        "admin-company-category-nav-item",
                                      "exact-active-class": "active",
                                    },
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      attrs: { icon: "fa-solid fa-list" },
                                    }),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "p-1" }, [
                                      _vm._v("Company Categories"),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-nav-item",
                                  {
                                    attrs: {
                                      to: { path: "/admin/bleed-preset" },
                                      "data-cy": "admin-bleed-preset-nav-item",
                                      "exact-active-class": "active",
                                    },
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      attrs: { icon: "fa-solid fa-crop" },
                                    }),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "p-1" }, [
                                      _vm._v("Bleed Preset"),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-nav-item",
                                  {
                                    attrs: {
                                      to: { path: "/admin/user-company" },
                                      "data-cy": "admin-user-company-nav-item",
                                      "exact-active-class": "active",
                                    },
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      attrs: { icon: "fa-solid fa-user-tie" },
                                    }),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "p-1" }, [
                                      _vm._v("User Companies"),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-nav-item",
                                  {
                                    attrs: {
                                      to: {
                                        path: "/admin/subscribtion-package",
                                      },
                                      "data-cy":
                                        "admin-subscription-package-nav-item",
                                      "exact-active-class": "active",
                                    },
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      attrs: { icon: "fa-solid fa-box" },
                                    }),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "p-1" }, [
                                      _vm._v("Subscription Packages"),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-nav-item",
                                  {
                                    attrs: {
                                      to: {
                                        path: "/admin/subscribtion-feature",
                                      },
                                      "data-cy":
                                        "admin-subscription-feature-nav-item",
                                      "exact-active-class": "active",
                                    },
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      attrs: { icon: "fa-solid fa-list-check" },
                                    }),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "p-1" }, [
                                      _vm._v("Subscription Features"),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-nav-item",
                                  {
                                    attrs: {
                                      to: { path: "/admin/subscribtion" },
                                      "data-cy": "admin-subscriptions-nav-item",
                                      "exact-active-class": "active",
                                    },
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      attrs: {
                                        icon: "fa-solid fa-file-contract",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "p-1" }, [
                                      _vm._v("Subscriptions"),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-nav-item",
                                  {
                                    attrs: {
                                      to: { path: "/admin/quota" },
                                      "data-cy": "admin-quota-nav-item",
                                      "exact-active-class": "active",
                                    },
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      attrs: { icon: "fa-solid fa-chart-pie" },
                                    }),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "p-1" }, [
                                      _vm._v("Quota Tracking"),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-nav-item",
                                  {
                                    attrs: {
                                      to: { path: "/admin/user-management" },
                                      "data-cy":
                                        "admin-user-management-nav-item",
                                      "exact-active-class": "active",
                                    },
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      attrs: { icon: "fa-solid fa-users" },
                                    }),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "p-1" }, [
                                      _vm._v("User Management"),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-nav-item",
                                  {
                                    attrs: {
                                      to: { path: "/admin/authority" },
                                      "data-cy":
                                        "admin-authority-management-nav-item",
                                      "exact-active-class": "active",
                                    },
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      attrs: {
                                        icon: "fa-solid fa-user-shield",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "p-1" }, [
                                      _vm._v("Roles & Groups Management"),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-nav-item",
                                  {
                                    attrs: {
                                      to: {
                                        path: "/admin/authority-markup-percentage",
                                      },
                                      "data-cy":
                                        "admin-authority-markup-percentage-nav-item",
                                      "exact-active-class": "active",
                                    },
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      attrs: { icon: "fa-solid fa-percent" },
                                    }),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "p-1" }, [
                                      _vm._v(
                                        "Authority Based Markup Percentage"
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-nav-item",
                                  {
                                    attrs: {
                                      to: { path: "/admin/transaction" },
                                      "data-cy": "admin-transactions-nav-item",
                                      "exact-active-class": "active",
                                    },
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      attrs: { icon: "fa-solid fa-exchange" },
                                    }),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "p-1" }, [
                                      _vm._v("Transactions"),
                                    ]),
                                  ],
                                  1
                                ),
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.hasAnyAuthority("GROUP_USER")
                            ? [
                                _c(
                                  "b-nav-item",
                                  {
                                    attrs: {
                                      to: "/user/quotation",
                                      "data-cy": "user-quotation-nav-item",
                                      "exact-active-class": "active",
                                    },
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      attrs: {
                                        icon: "fa-solid fa-file-invoice-dollar",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "p-1" }, [
                                      _vm._v("Quotation"),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-nav-item",
                                  {
                                    attrs: {
                                      to: "/user/profile",
                                      "data-cy": "user-profile-nav-item",
                                      "exact-active-class": "active",
                                    },
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      attrs: { icon: "fa-solid fa-user" },
                                    }),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "p-1" }, [
                                      _vm._v("Profile"),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _vm.hasAnyAuthority("ROLE_ADMIN")
                                  ? [
                                      _c(
                                        "b-nav-item",
                                        {
                                          attrs: {
                                            to: "/user/paper-category",
                                            "data-cy":
                                              "user-paper-category-nav-item",
                                            "exact-active-class": "active",
                                          },
                                        },
                                        [
                                          _c("font-awesome-icon", {
                                            attrs: { icon: "clipboard-list" },
                                          }),
                                          _vm._v(" "),
                                          _c("span", { staticClass: "p-1" }, [
                                            _vm._v("Paper Categories"),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-nav-item",
                                        {
                                          attrs: {
                                            to: {
                                              path: "/user/paper-grammage",
                                            },
                                            "data-cy":
                                              "user-paper-grammage-nav-item",
                                            "exact-active-class": "active",
                                          },
                                        },
                                        [
                                          _c("font-awesome-icon", {
                                            attrs: { icon: "weight" },
                                          }),
                                          _vm._v(" "),
                                          _c("span", { staticClass: "p-1" }, [
                                            _vm._v("Paper Grammages"),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-nav-item",
                                        {
                                          attrs: {
                                            to: { path: "/user/paper-type" },
                                            "data-cy":
                                              "user-paper-type-nav-item",
                                            "exact-active-class": "active",
                                          },
                                        },
                                        [
                                          _c("font-awesome-icon", {
                                            attrs: { icon: "file" },
                                          }),
                                          _vm._v(" "),
                                          _c("span", { staticClass: "p-1" }, [
                                            _vm._v("Paper Types"),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-nav-item",
                                        {
                                          attrs: {
                                            to: { path: "/user/plate-color" },
                                            "data-cy":
                                              "user-plate-color-nav-item",
                                            "exact-active-class": "active",
                                          },
                                        },
                                        [
                                          _c("font-awesome-icon", {
                                            attrs: { icon: "palette" },
                                          }),
                                          _vm._v(" "),
                                          _c("span", { staticClass: "p-1" }, [
                                            _vm._v("Plate Colors"),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-nav-item",
                                        {
                                          attrs: {
                                            to: { path: "/user/agent" },
                                            "data-cy": "user-agents-nav-item",
                                            "exact-active-class": "active",
                                          },
                                        },
                                        [
                                          _c("font-awesome-icon", {
                                            attrs: { icon: "users" },
                                          }),
                                          _vm._v(" "),
                                          _c("span", { staticClass: "p-1" }, [
                                            _vm._v("Agents"),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-nav-item",
                                        {
                                          attrs: {
                                            to: { path: "/user/customer" },
                                            "data-cy": "user-customer-nav-item",
                                            "exact-active-class": "active",
                                          },
                                        },
                                        [
                                          _c("font-awesome-icon", {
                                            attrs: { icon: "user" },
                                          }),
                                          _vm._v(" "),
                                          _c("span", { staticClass: "p-1" }, [
                                            _vm._v("Customers"),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-nav-item",
                                        {
                                          attrs: {
                                            to: { path: "/user/reporting" },
                                            "data-cy":
                                              "user-reporting-nav-item",
                                            "exact-active-class": "active",
                                          },
                                        },
                                        [
                                          _c("font-awesome-icon", {
                                            attrs: { icon: "chart-bar" },
                                          }),
                                          _vm._v(" "),
                                          _c("span", { staticClass: "p-1" }, [
                                            _vm._v("Reporting"),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-nav-item",
                                        {
                                          attrs: {
                                            to: { path: "/user/company" },
                                            "data-cy": "user-company-nav-item",
                                            "exact-active-class": "active",
                                          },
                                        },
                                        [
                                          _c("font-awesome-icon", {
                                            attrs: { icon: "building" },
                                          }),
                                          _vm._v(" "),
                                          _c("span", { staticClass: "p-1" }, [
                                            _vm._v("Company Profile"),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-nav-item",
                                        {
                                          attrs: {
                                            to: {
                                              path: "/user/finishing-property",
                                            },
                                            "data-cy":
                                              "user-finishing-property-nav-item",
                                            "exact-active-class": "active",
                                          },
                                        },
                                        [
                                          _c("font-awesome-icon", {
                                            attrs: {
                                              icon: "fa-solid fa-paint-roller",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("span", { staticClass: "p-1" }, [
                                            _vm._v("Finishing Properties"),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]
                                  : _vm._e(),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                class: {
                  "col-md-9 col-lg-10": _vm.authenticated,
                  "col-12": !_vm.authenticated,
                },
              },
              [_c("router-view")],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "b-modal",
            { attrs: { id: "login-page", "hide-footer": "", lazy: "" } },
            [
              _c(
                "span",
                {
                  attrs: {
                    slot: "modal-title",
                    "data-cy": "loginTitle",
                    id: "login-title",
                  },
                  domProps: { textContent: _vm._s(_vm.$t("login.title")) },
                  slot: "modal-title",
                },
                [_vm._v("Sign in")]
              ),
              _vm._v(" "),
              _c("login-form"),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }