import axios from 'axios';

const baseCompanyImageApiUrl = 'api/upload/company-image';

const baseImageApiUrl = 'api/company-image';

import { lookup } from 'mime-types';

export default class FileService {
  uploadCompanyImage(formData: FormData): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(baseCompanyImageApiUrl, formData)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  getCompanyImageUrl(objectName: string) {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseImageApiUrl}?objectName=${objectName}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  deleteCompanyImage(objectName: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${baseImageApiUrl}?objectName=${objectName}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  convertImageUrlToBase64DataUri(imageUrl: string): Promise<string> {
    return new Promise((resolve, reject) => {
      axios
        .get(imageUrl, { responseType: 'arraybuffer' })
        .then(async response => {
          const imageBuffer = response.data;

          const mimeType = await this.getMimeTypeFromUrl(imageUrl);
          if (!mimeType) {
            throw new Error('Unable to determine MIME type');
          } else {
            const base64String = Buffer.from(imageBuffer).toString('base64');
            const base64DataUri = `data:${mimeType};base64,${base64String}`;
            return base64DataUri;
          }
        })
        .catch(error => {
          console.error('Error converting image to Base64:', error);
          reject(error);
        });
    });
  }

  async getMimeTypeFromUrl(url: string): Promise<string | false> {
    return lookup(url);
  }
}
