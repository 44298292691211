import axios from 'axios';

import buildPaginationQueryOpts from '@/shared/sort/sorts';

import { IQuote } from '@/shared/model/quote.model';

const baseApiUrl = 'api/quotes';

const companyApiUrl = 'api/quotes-by-user-company';

export default class QuoteService {
  retrieveAllByCompany(paginationQuery: { page: number; size: number; sort: any[] }) {
    throw new Error('Method not implemented.');
  }

  findByCode(quoteCode: any): Promise<IQuote> {
    return new Promise<IQuote>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/code/${quoteCode}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public find(id: number): Promise<IQuote> {
    return new Promise<IQuote>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveOwnByUserCompany(paginationQuery?: any) {
    return new Promise<any>((resolve, reject) => {
      axios
        .get('/api/quotes-by-user-company' + `?${buildPaginationQueryOpts(paginationQuery)}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public searchQuotesByUserCompany(keyword: string, paginationQuery?: any) {
    return new Promise<any>((resolve, reject) => {
      axios
        .get('/api/quotes-by-user-company/search' + `?keyword=${keyword}&${buildPaginationQueryOpts(paginationQuery)}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public countStatusesByUserCompany() {
    return new Promise<any>((resolve, reject) => {
      axios
        .get('/api/quotes-by-user-company/status-count')
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public countQuotesByMonthYearAndCompany() {
    return new Promise<any>((resolve, reject) => {
      axios
        .get('/api/quotes-by-user-company/count-monthyearcompany')
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public approveQuote(quoteCode: string, lineItemId: number) {
    return new Promise<IQuote>((resolve, reject) => {
      axios
        .post(`${companyApiUrl}/code/${quoteCode}/approve/${lineItemId}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public rejectQuote(quoteCode: string, rejectedReason?: string) {
    return new Promise<IQuote>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/code/${quoteCode}/reject/`, {
          rejectedReason: rejectedReason,
        })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieve(paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl + `?${buildPaginationQueryOpts(paginationQuery)}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public delete(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public create(entity: IQuote): Promise<IQuote> {
    return new Promise<IQuote>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public createByCompany(entity: IQuote): Promise<any> {
    return new Promise<IQuote>((resolve, reject) => {
      axios
        .post(`${companyApiUrl}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public update(entity: IQuote): Promise<IQuote> {
    return new Promise<IQuote>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public updateByCompany(entity: IQuote): Promise<IQuote> {
    return new Promise<IQuote>((resolve, reject) => {
      axios
        .put(`${companyApiUrl}/${entity.code}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public partialUpdate(entity: IQuote): Promise<IQuote> {
    return new Promise<IQuote>((resolve, reject) => {
      axios
        .patch(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
