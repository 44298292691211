import axios from 'axios';

import buildPaginationQueryOpts from '@/shared/sort/sorts';

import { IPlateColor } from '@/shared/model/plate-color.model';

const baseApiUrl = 'api/plate-colors';

const plateColorsByCompanyUrl = 'api/plate-colors-by-company';

const plateColorsNoCompanyUrl = 'api/plate-colors-no-company';

export default class PlateColorService {
  exportToCSVByCompany(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${plateColorsByCompanyUrl}/export-csv`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public uploadFile(file: File): Promise<any> {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('file', file);
      axios
        .post(plateColorsByCompanyUrl + '/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public find(id: number): Promise<IPlateColor> {
    return new Promise<IPlateColor>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public findByCompany(id: number): Promise<IPlateColor> {
    return new Promise<IPlateColor>((resolve, reject) => {
      axios
        .get(`${plateColorsByCompanyUrl}/${id}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieve(paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl + `?${buildPaginationQueryOpts(paginationQuery)}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveByCompany(paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(plateColorsByCompanyUrl + `?${buildPaginationQueryOpts(paginationQuery)}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveByCompanyUnpaged(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(plateColorsByCompanyUrl + `?unpaged=true`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveWithoutCompany(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(plateColorsNoCompanyUrl)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public delete(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public deleteByCompany(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${plateColorsByCompanyUrl}/${id}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public create(entity: IPlateColor): Promise<IPlateColor> {
    return new Promise<IPlateColor>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public createByCompany(entity: IPlateColor): Promise<IPlateColor> {
    return new Promise<IPlateColor>((resolve, reject) => {
      axios
        .post(`${plateColorsByCompanyUrl}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public update(entity: IPlateColor): Promise<IPlateColor> {
    return new Promise<IPlateColor>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public updateByCompany(entity: IPlateColor): Promise<IPlateColor> {
    return new Promise<IPlateColor>((resolve, reject) => {
      axios
        .put(`${plateColorsByCompanyUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public partialUpdate(entity: IPlateColor): Promise<IPlateColor> {
    return new Promise<IPlateColor>((resolve, reject) => {
      axios
        .patch(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
