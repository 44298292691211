var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("font-awesome-icon", {
    attrs: {
      icon:
        _vm.currentOrder === _vm.fieldName
          ? _vm.reverse
            ? "sort-down"
            : "sort-up"
          : "sort",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }