export default class QuotationUiService {
  openNewQuotationModal(instance: Vue) {
    instance.$emit('bv::show::modal', 'open-new-quotation-modal');
  }

  public openCreateQuotationModal(instance: Vue) {
    instance.$emit('bv::show::modal', 'quotation-create-page');
  }

  public openCalculationErrorModal(instance: Vue) {
    instance.$emit('bv::show::modal', 'upper-section-calculation-error-modal');
  }

  public openPaperCannotBePrintedModal(instance: Vue) {
    instance.$emit('bv::show::modal', 'paper-cannot-be-printed-modal');
  }

  public openApproveQuotationModal(instance: Vue) {
    instance.$emit('bv::show::modal', 'approve-quotation-modal');
  }

  public closeApproveQuotationModal(instance: Vue) {
    instance.$emit('bv::hide::modal', 'approve-quotation-modal');
  }

  public openRejectQuotationModal(instance: Vue) {
    instance.$emit('bv::show::modal', 'reject-quotation-modal');
  }

  public closeRejectQuotationModal(instance: Vue) {
    instance.$emit('bv::hide::modal', 'reject-quotation-modal');
  }

  public closePaperCannotBePrintedModal(instance: Vue) {
    instance.$emit('bv::hide::modal', 'paper-cannot-be-printed-modal');
  }

  public closeCalculationErrorModal(instance: Vue) {
    instance.$emit('bv::hide::modal', 'upper-section-calculation-error-modal');
  }
}
