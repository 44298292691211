import { ContentDesign } from '@/shared/model/content-design.model';
import { add, multiply, divide, create, all } from 'mathjs';

const math = create(all);

export default class FinishingCalculationService {
  public calculateSpotUVQuantity(
    cut: number,
    option: boolean,
    lengthAfterCut: number,
    amount: number,
    finishedProductTotalQuantity: number
  ): number {
    if (cut === 1) {
      if (option) {
        return lengthAfterCut * (amount / finishedProductTotalQuantity) * 2 || 0;
      } else {
        return 0;
      }
    } else {
      if (option) {
        return lengthAfterCut * (amount / finishedProductTotalQuantity) || 0;
      } else {
        return 0;
      }
    }
  }

  // calculate soft touch
  public calculatej3637(cut: number, option: boolean, paperNeeded: number, amount: number, finishProductTotalQuantity: number): number {
    if (cut === 1) {
      if (option) {
        return paperNeeded * (amount / finishProductTotalQuantity) * 2;
      } else {
        return 0;
      }
    } else {
      if (option) {
        return paperNeeded * (amount / finishProductTotalQuantity);
      } else {
        return 0;
      }
    }
  }

  public softtouchsize(
    cut: number,
    optionFront: boolean,
    optionBack: boolean,
    paperNeeded: number,
    amountFront: number,
    amountBack: number,
    finishProductTotalQuantity: number,
    softTouchLaminationPrice: number,
    minimumCost: number,
    offsetLamTestingQuantity: number,
    squareInches: number
  ): number {
    const j36 = this.calculatej3637(cut, optionFront, paperNeeded, amountFront, finishProductTotalQuantity);
    const j37 = this.calculatej3637(cut, optionBack, paperNeeded, amountBack, finishProductTotalQuantity);
    const j38 = add(j37, j36);
    const j39 = offsetLamTestingQuantity;
    const j40 = add(j38, j39);
    const B4 = squareInches;

    if (j38 === 0) {
      return 0;
    } else if (cut !== 1) {
      if (j40 * B4 * softTouchLaminationPrice < minimumCost) {
        return minimumCost;
      } else {
        return j40 * B4 * softTouchLaminationPrice;
      }
    } else {
      if (j40 * (B4 / 2) * softTouchLaminationPrice < minimumCost) {
        return minimumCost;
      } else {
        return j40 * (B4 / 2) * softTouchLaminationPrice;
      }
    }
  }

  public softTouchOffsetCal(
    cut: number,
    optionFront: boolean,
    optionBack: boolean,
    paperNeeded: number,
    amountFront: number,
    amountBack: number,
    finishProductTotalQuantity: number,
    softTouchLaminationPrice: number,
    minimumCost: number,
    offsetLamTestingQuantity: number,
    squareInches: number
  ): number {
    const a29 = this.softtouchsize(
      cut,
      optionFront,
      optionBack,
      paperNeeded,
      amountFront,
      amountBack,
      finishProductTotalQuantity,
      softTouchLaminationPrice,
      minimumCost,
      offsetLamTestingQuantity,
      squareInches
    );

    const j36 = this.calculatej3637(cut, optionFront, paperNeeded, amountFront, finishProductTotalQuantity);
    const j37 = this.calculatej3637(cut, optionBack, paperNeeded, amountBack, finishProductTotalQuantity);
    const j38 = add(j36, j37);

    const k36 = j36 / j38;
    const k37 = j37 / j38;

    const l36 = a29 * k36;
    const l37 = a29 * k37;

    const l40 = add(l37, l36);

    return l40;
  }

  // ---------------- Calculate Emboss / Deboss ----------------
  public CalculatefnsSize(opt, width, length) {
    if (opt) {
      return (width * length) / 645;
    }
    return 0;
  }

  public CalculateEDBossSum(
    option: boolean,
    width: number,
    length: number,
    amount: number,
    embossBlockPrice: number,
    embossCostPer1000: number,
    minimumCost: number,
    actualUpsPerPaper: number,
    cut: number
  ): number {
    const size = this.CalculatefnsSize(option, width, length);

    // calculate quantity (G58 -> G69)
    let quantity: number;
    if (size === 0) {
      quantity = 0;
    } else {
      if (cut === 1) {
        quantity = Math.ceil(amount / (actualUpsPerPaper / 2) / 1000) * embossCostPer1000;
      } else {
        quantity = Math.ceil(amount / actualUpsPerPaper / 1000) * embossCostPer1000;
      }
    }

    // ------------
    // calculate block
    let block: number;
    if (size === 0) {
      block = 0;
    } else {
      if (size * embossBlockPrice < minimumCost) {
        block = minimumCost;
      } else {
        block = size * embossBlockPrice;
      }
    }
    // ------------
    return block * actualUpsPerPaper + quantity;
  }

  // ---------------- Calculate DieCut price ----------------
  // Calculate diecut size (b61 -> ...)
  public CalculateDiecutSize(option: boolean, width: number, length: number): number {
    if (option === true) {
      return add(width, length);
    }
    return 0;
  }

  // Calculate print count mutiplier (b7)
  public PrintCountMultiplier(printTechnique: string, upsAfterCut: number): number {
    if (printTechnique === 'Digital Print') {
      return 4;
    }
    return upsAfterCut;
  }

  // Calculate I(100->104)
  public calculateDiecutQuantityPrice(
    option: boolean,
    width: number,
    length: number,
    amount: number,
    printTechnique: string,
    upsAfterCut: number,
    diecutPricePer1000: number,
    actualUpsPerPaper: number,
    cut: number
  ): number {
    const diecutSize: number = this.CalculateDiecutSize(option, width, length);
    if (diecutSize === 0) {
      return 0;
    } else {
      if (cut === 1) {
        return Math.ceil(amount / actualUpsPerPaper / 2 / 1000) * diecutPricePer1000;
      } else {
        return Math.ceil(amount / actualUpsPerPaper / 1000) * diecutPricePer1000;
      }
    }
  }

  // B66 -> B70
  public CalculateKnifePrice(option: boolean, numberOfKnife: number, diecutKnifeMinAmount: number): number {
    if (option === true) {
      return numberOfKnife > diecutKnifeMinAmount ? numberOfKnife * 15 : numberOfKnife * 20;
    } else {
      return 0;
    }
  }

  // Calculate Block (E100->104)
  public calculateDiecutBlock(
    option: boolean,
    width: number,
    length: number,
    minimumCost: number,
    numberOfKnife: number,
    diecutKnifeMinAmount: number
  ): number {
    const diecutKnifePrice = this.CalculateKnifePrice(option, numberOfKnife, diecutKnifeMinAmount);
    const diecutSize = this.CalculateDiecutSize(option, width, length);

    if (diecutSize === 0) {
      return 0;
    }

    return diecutSize * 0.25 < minimumCost ? minimumCost : diecutSize * 0.25 + diecutKnifePrice;
  }

  // Calculate G(100->104)
  public calculateBlockAndUp(
    option: boolean,
    width: number,
    length: number,
    minimumCost: number,
    printTechnique: string,
    upsAfterCut: number,
    actualUpsPerPaper: number,
    numberOfKnife: number,
    diecutKnifeMinAmount: number,
    cut: number
  ): number {
    const diecutBlock = this.calculateDiecutBlock(option, width, length, minimumCost, numberOfKnife, diecutKnifeMinAmount);

    if (printTechnique === 'Digital Print') {
      return diecutBlock * upsAfterCut;
    } else {
      if (cut === 1) {
        return (diecutBlock * actualUpsPerPaper) / 2;
      } else {
        return diecutBlock * actualUpsPerPaper;
      }
    }
  }

  public calculateDieCutPrice(
    option: boolean,
    width: number,
    length: number,
    amount: number,
    printTechnique: string,
    upsAfterCut: number,
    diecutPricePer1000: number,
    minimumCost: number,
    actualUpsPerPaper: number,
    numberOfKnife: number,
    diecutKnifeMinAmount: number,
    cut: number
  ): number {
    const i = this.calculateDiecutQuantityPrice(
      option,
      width,
      length,
      amount,
      printTechnique,
      upsAfterCut,
      diecutPricePer1000,
      actualUpsPerPaper,
      cut
    );
    const g = this.calculateBlockAndUp(
      option,
      width,
      length,
      minimumCost,
      printTechnique,
      upsAfterCut,
      actualUpsPerPaper,
      numberOfKnife,
      diecutKnifeMinAmount,
      cut
    );

    return add(g, i);
  }

  // ---------- Calculate B57 (Hot Stamping 2531 Ups) ----------
  public onlyUnique(value: any, index: number, self: any[]): boolean {
    return self.indexOf(value) === index;
  }

  public SUGGESTEDUPS(ups: number, lastCount: number): number {
    const upsFromPage = ups;
    const countFromPage = lastCount;
    let resultForLoop = 1;
    let lastCountForLoop = 0;

    for (lastCountForLoop = countFromPage + 1; lastCountForLoop < upsFromPage + 1; lastCountForLoop++) {
      resultForLoop = upsFromPage / lastCountForLoop;

      if (Number.isInteger(resultForLoop)) {
        break;
      }
    }

    return upsFromPage / resultForLoop;
  }

  public C23FromFormula(upsAfterCut: number, areaInMM: number, actualUpsPerPaper: number): number {
    const upsCutSuggestion: number[] = [];
    let a = 0;

    for (let i = 11; i <= 20; i++) {
      a = this.SUGGESTEDUPS(upsAfterCut, a);
      upsCutSuggestion.push(a);
    }

    const unique = upsCutSuggestion.filter((value, index, self) => self.indexOf(value) === index);

    let max = unique[0] * areaInMM;
    let index = unique[0];

    for (let i = 1; i < unique.length; i++) {
      if (unique[i] * areaInMM > max && unique[i] * areaInMM < 499999) {
        max = unique[i] * areaInMM;
        index = unique[i];
      }
    }

    if (index > actualUpsPerPaper) {
      return actualUpsPerPaper;
    }

    return index;
  }

  //b57
  public calcHotStamping2531Ups(
    upsAfterCut: number,
    upsAfterCutDigital: number,
    printTechnique: string,
    areaInMM: number,
    actualUpsPerPaper: number
  ): number {
    const c23 = this.C23FromFormula(upsAfterCut, areaInMM, actualUpsPerPaper);
    if (printTechnique === 'Digital Print') {
      return upsAfterCutDigital;
    }
    return c23;
  }

  //b58
  public calcHotStampingNormalUps(upsAfterCutDigital: number, printTechnique: string, actualUpsPerPaper: number, cut: number): number {
    if (printTechnique === 'Digital Print') {
      return upsAfterCutDigital;
    }
    if (cut === 1) {
      return actualUpsPerPaper / 2;
    }
    return actualUpsPerPaper;
  }

  // b59
  public HotStampingQuantityMultiplier(
    upsAfterCut: number,
    upsAfterCutDigital: number,
    printTechnique: string,
    actualUpsPerPaper: number,
    areaInMM: number,
    cut: number
  ): number {
    return (
      this.calcHotStampingNormalUps(upsAfterCutDigital, printTechnique, actualUpsPerPaper, cut) /
      this.calcHotStamping2531Ups(upsAfterCut, upsAfterCutDigital, printTechnique, areaInMM, actualUpsPerPaper)
    );
  }

  // ---------- Calculate B46->B50 and B51 -> B55 (Hot Stamping Front/Back 1->5 Size) ----------
  // B46 -> B50 | B51 -> B55
  public calcHotStampingSize(option: boolean, width: number, length: number): number {
    if (option) {
      return (width * length) / 645;
    }
    return 0;
  }

  /*
   *
   * ---------- Calculate M72 (Sum of 2531) = K72 + K79 ----------
   *
   */
  // Calculate K72 (Front) / K79 (Back) (Total price hot stamping front) = SUM (E72 -> E76) x B57 + SUM (I72 -> I76)
  // => Calculate SUM (E72 -> E76) : Hot Stamping front 2531
  public calcHotStampingFrontBackSum(
    option: boolean,
    widthArray: number[],
    lengthArray: number[],
    upsAfterCut: number,
    upsAfterCutDigital: number,
    printTechnique: string,
    hotStampingBlockPrice: number,
    hotStampingBlockMinimumCost: number,
    areaInMM: number,
    actualUpsPerPaper: number
  ): number {
    const b57 = this.calcHotStamping2531Ups(upsAfterCut, upsAfterCutDigital, printTechnique, areaInMM, actualUpsPerPaper);
    const b: number[] = [];

    for (let i = 0; i < widthArray.length; i++) {
      const a = this.calcHotStampingSize(option, widthArray[i], lengthArray[i]);
      b.push(a);
    }

    let sum = 0;
    for (let i = 0; i < b.length; i++) {
      let a = 0;
      if (b[i] === 0) {
        a = 0;
      } else if (b[i] * hotStampingBlockPrice < hotStampingBlockMinimumCost) {
        a = hotStampingBlockMinimumCost;
      } else {
        a = b[i] * hotStampingBlockPrice;
      }
      sum += a * b57;
    }

    return sum;
  }

  public calcHotStampingFrontBackSumB58(
    option: boolean,
    widthArray: number[],
    lengthArray: number[],
    upsAfterCut: number,
    upsAfterCutDigital: number,
    printTechnique: string,
    hotStampingBlockPrice: number,
    hotStampingBlockMinimumCost: number,
    areaInMM: number,
    actualUpsPerPaper: number,
    cut: number
  ): number {
    const b57 = this.calcHotStamping2531Ups(upsAfterCut, upsAfterCutDigital, printTechnique, areaInMM, actualUpsPerPaper);
    const b58 = this.calcHotStampingNormalUps(upsAfterCutDigital, printTechnique, actualUpsPerPaper, cut);
    const b: number[] = [];

    for (let i = 0; i < widthArray.length; i++) {
      const a = this.calcHotStampingSize(option, widthArray[i], lengthArray[i]);
      b.push(a);
    }

    let sum = 0;
    for (let i = 0; i < b.length; i++) {
      let a = 0;
      if (b[i] === 0) {
        a = 0;
      } else if (b[i] * hotStampingBlockPrice < hotStampingBlockMinimumCost) {
        a = hotStampingBlockMinimumCost;
      } else {
        a = b[i] * hotStampingBlockPrice;
      }
      sum += a * b58;
    }

    return sum;
  }

  // => Calculate SUM (I72 -> I76) : Hot Stamping front 2531
  public calcHotStampingFrontBack2531QuantityPrice(
    option: boolean,
    widthArray: number[],
    lengthArray: number[],
    upsAfterCut: number,
    actualUpsPerPaper: number,
    upsAfterCutDigital: number,
    printTechnique: string,
    hotStamping2531Price: number,
    hotStamping2531MinimumCost: number,
    areaInMM: number,
    amount: number,
    cut: number
  ): number {
    const b57 = this.calcHotStamping2531Ups(upsAfterCut, upsAfterCutDigital, printTechnique, areaInMM, actualUpsPerPaper);
    const b59 = this.HotStampingQuantityMultiplier(upsAfterCut, upsAfterCutDigital, printTechnique, actualUpsPerPaper, areaInMM, cut);
    const b: number[] = [];

    for (let i = 0; i < 5; i++) {
      const a = this.calcHotStampingSize(option, widthArray[i], lengthArray[i]);
      b.push(a);
    }

    // G72
    let g = 0;
    if (b[0] === 0) {
      g = 0;
    } else {
      g = amount;
    }

    let sum = 0;
    for (let i = 0; i < widthArray.length; i++) {
      let a = 0;
      if (b[i] === 0) {
        a = 0;
      } else {
        a = b[i] * g * hotStamping2531Price < hotStamping2531MinimumCost ? hotStamping2531MinimumCost : b[i] * g * hotStamping2531Price;
        sum += a;
      }
    }

    return sum;
  }

  public calcSumOf2531(
    optionFront: boolean,
    optionBack: boolean,
    widthArrayFront: number[],
    lengthArrayFront: number[],
    widthArrayBack: number[],
    lengthArrayBack: number[],
    amountFront: number,
    amountBack: number,
    upsAfterCut: number,
    upsAfterCutDigital: number,
    printTechnique: string,
    hotStampingBlockPrice: number,
    hotStampingBlockMinimumCost: number,
    areaInMM: number,
    hotStamping2531Price: number,
    hotStamping2531MinimumCost: number,
    actualUpsPerPaper: number,
    cut: number
  ): number {
    const b57 = this.calcHotStamping2531Ups(upsAfterCut, upsAfterCutDigital, printTechnique, areaInMM, actualUpsPerPaper);

    console.log('b57:', b57);

    // front
    const SumE72toE76 = this.calcHotStampingFrontBackSum(
      optionFront,
      widthArrayFront,
      lengthArrayFront,
      upsAfterCut,
      upsAfterCutDigital,
      printTechnique,
      hotStampingBlockPrice,
      hotStampingBlockMinimumCost,
      areaInMM,
      actualUpsPerPaper
    );
    console.log('SumE72toE76:', SumE72toE76);
    const SumI72toI76 = this.calcHotStampingFrontBack2531QuantityPrice(
      optionFront,
      widthArrayFront,
      lengthArrayFront,
      upsAfterCut,
      actualUpsPerPaper,
      upsAfterCutDigital,
      printTechnique,
      hotStamping2531Price,
      hotStamping2531MinimumCost,
      areaInMM,
      amountFront,
      cut
    );
    console.log('SumI72toI76:', SumI72toI76);
    const k72 = math.add(SumE72toE76, SumI72toI76);
    console.log('k72:', k72);

    // back
    const SumE79toE83 = this.calcHotStampingFrontBackSum(
      optionBack,
      widthArrayBack,
      lengthArrayBack,
      upsAfterCut,
      upsAfterCutDigital,
      printTechnique,
      hotStampingBlockPrice,
      hotStampingBlockMinimumCost,
      areaInMM,
      actualUpsPerPaper
    );
    console.log('SumE79toE83:', SumE79toE83);
    const SumI79toI83 = this.calcHotStampingFrontBack2531QuantityPrice(
      optionBack,
      widthArrayBack,
      lengthArrayBack,
      upsAfterCut,
      actualUpsPerPaper,
      upsAfterCutDigital,
      printTechnique,
      hotStamping2531Price,
      hotStamping2531MinimumCost,
      areaInMM,
      amountBack,
      cut
    );
    console.log('SumI79toI83:', SumI79toI83);
    const k79 = math.add(SumE79toE83, SumI79toI83);
    console.log('k79:', k79);

    return math.add(k72, k79);
  }

  // Calculate K86 = SUM (E86 -> E90) x B58 + SUM (I86 -> I90)
  // => Calculate SUM (E86 -> E90): Hot stamping front normal
  // => SAME AS E72 -> E76 -> use same function HotStampingFrontBackSum
  // => Calculate SUM (I86 -> I90)
  public calcHotStampingFrontBackNormalQuantityPrice(
    option: boolean,
    widthArray: number[],
    lengthArray: number[],
    actualUpsPerPaper: number,
    upsAfterCutDigital: number,
    printTechnique: string,
    hotStampingNormalPrice: number,
    hotStampingNormalMinimumCost: number,
    amount: number,
    cut: number
  ): number {
    const b58 = this.calcHotStampingNormalUps(upsAfterCutDigital, printTechnique, actualUpsPerPaper, cut);
    const b: number[] = [];
    for (let i = 0; i < widthArray.length; i++) {
      const a = this.calcHotStampingSize(option, widthArray[i], lengthArray[i]);
      b.push(a);
    }
    let g = 0;
    if (b[0] == 0) {
      g = 0;
    } else {
      g = amount;
    }

    let sum = 0;
    for (let i = 0; i < widthArray.length; i++) {
      let a = 0;
      if (b[i] == 0) {
        a = 0;
      } else if (b[i] * g * hotStampingNormalPrice < hotStampingNormalMinimumCost) {
        a = hotStampingNormalMinimumCost;
      } else {
        a = b[i] * g * hotStampingNormalPrice;
      }
      sum += a;
    }
    return sum;
  }

  // => Calculate K93 = SUM (E93 -> E97) * B57 + SUM(I93 -> I97)
  // => Calculate SUM(E93 -> E97): Hot Stamping Back Normal
  // => SAME AS E72 -> E76 -> use same function HotStampingFrontBackSum
  // => Calculate SUM (I93 -> I97)
  // => SAME AS SUM (I86 => I90)
  // => USE Function HotStampingFrontBackNormalQuantityPrice

  // M73
  public calcSumOfNormal(
    optionFront: boolean,
    optionBack: boolean,
    widthArrayFront: number[],
    lengthArrayFront: number[],
    widthArrayBack: number[],
    lengthArrayBack: number[],
    amountFront: number,
    amountBack: number,
    upsAfterCut: number,
    upsAfterCutDigital: number,
    printTechnique: string,
    hotStampingBlockPrice: number,
    hotStampingBlockMinimumCost: number,
    areaInMM: number,
    hotStampingNormalPrice: number,
    hotStampingNormalMinimumCost: number,
    actualUpsPerPaper: number,
    cut: number
  ): number {
    const b57 = this.calcHotStamping2531Ups(upsAfterCut, upsAfterCutDigital, printTechnique, areaInMM, actualUpsPerPaper);
    const b58 = this.calcHotStampingNormalUps(upsAfterCutDigital, printTechnique, actualUpsPerPaper, cut);
    // front
    const SumE86toE90 = this.calcHotStampingFrontBackSumB58(
      optionFront,
      widthArrayFront,
      lengthArrayFront,
      upsAfterCut,
      upsAfterCutDigital,
      printTechnique,
      hotStampingBlockPrice,
      hotStampingBlockMinimumCost,
      areaInMM,
      actualUpsPerPaper,
      cut
    );
    console.log('SumE86toE90:', SumE86toE90);
    const SumI86toI90 = this.calcHotStampingFrontBackNormalQuantityPrice(
      optionFront,
      widthArrayFront,
      lengthArrayFront,
      actualUpsPerPaper,
      upsAfterCutDigital,
      printTechnique,
      hotStampingNormalPrice,
      hotStampingNormalMinimumCost,
      amountFront,
      cut
    );
    console.log('SumI86toI90:', SumI86toI90);
    const k86 = math.add(SumE86toE90, SumI86toI90);
    console.log('k86:', k86);
    // back
    const SumE93toE97 = this.calcHotStampingFrontBackSumB58(
      optionBack,
      widthArrayBack,
      lengthArrayBack,
      upsAfterCut,
      upsAfterCutDigital,
      printTechnique,
      hotStampingBlockPrice,
      hotStampingBlockMinimumCost,
      areaInMM,
      actualUpsPerPaper,
      cut
    );
    console.log('SumE93toE97:', SumE93toE97);
    const SumI93toU97 = this.calcHotStampingFrontBackNormalQuantityPrice(
      optionBack,
      widthArrayBack,
      lengthArrayBack,
      actualUpsPerPaper,
      upsAfterCutDigital,
      printTechnique,
      hotStampingNormalPrice,
      hotStampingNormalMinimumCost,
      amountBack,
      cut
    );
    console.log('SumI93toU97:', SumI93toU97);
    const k93 = math.add(SumE93toE97, SumI93toU97);
    return math.add(k86, k93);
  }

  public calculatePerfectBindPrice(
    totalDesignOrPages: number,
    actualUpsPerPaper: number,
    quantity: number,
    colorname: string,
    option: boolean,
    perfectBindMinimumCharge: number,
    bookUps: number,
    perfectBindPerSectionCharge: number,
    perfectBindBindingCharge: number,
    cut: number
  ): number {
    const e108 = this.calcPriceCountWithoutLow(
      totalDesignOrPages,
      actualUpsPerPaper,
      quantity,
      colorname,
      bookUps,
      perfectBindPerSectionCharge,
      perfectBindBindingCharge,
      cut
    );
    if (!option) {
      return 0;
    }
    return e108 < perfectBindMinimumCharge ? perfectBindMinimumCharge : e108;
  }

  public calculateDigitalPerfectBindPrice(
    quantity: number,
    option: boolean,
    perfectBindMinimumCharge: number,
    perfectBindPricePerPiece: number
  ): number {
    if (!option) {
      return 0;
    }
    const multiplicationResult = math.multiply(perfectBindPricePerPiece, quantity);
    if (multiplicationResult < perfectBindMinimumCharge) {
      return perfectBindMinimumCharge;
    } else {
      return multiplicationResult;
    }
  }

  // calculate e108
  public calcPriceCountWithoutLow(
    totalDesigns: number,
    actualUpsPerPaper: number,
    quantity: number,
    colorname: string,
    bookUps: number,
    perfectBindPerSectionCharge: number,
    perfectBindBindingCharge: number,
    cut: number
  ): number {
    const E107 = this.twoSidePrint(colorname);
    let result = 0;
    if (E107) {
      result = (Math.ceil(totalDesigns / bookUps / 2) * perfectBindPerSectionCharge + perfectBindBindingCharge) * quantity;
    } else {
      result = (Math.ceil(totalDesigns / bookUps) * perfectBindPerSectionCharge + perfectBindBindingCharge) * quantity;
    }
    // result =
    //   (Math.ceil(E107 ? Math.ceil(totalDesigns / bookUps / 2) : Math.ceil(totalDesigns / bookUps)) * perfectBindPerSectionCharge +
    //     perfectBindBindingCharge) *
    //   quantity;
    return result;
  }

  // calculate e107
  public twoSidePrint(colorname: string): boolean {
    return colorname === '4C+4C' || colorname === '4C+1C' || colorname === '1C+1C';
  }

  public calcStapleBindCost(
    option: boolean,
    quantity: number,
    stapleBindLowThreshold: number,
    stapleBindLowFlatPrice: number,
    stapleBindMinimumCharge: number,
    stapleBindIncrementPrice: number,
    companyCategory: string
  ): number {
    if (!option) {
      return 0;
    }

    if (companyCategory === 'digital') {
      return stapleBindLowFlatPrice;
    } else {
      if (quantity < stapleBindLowThreshold) {
        return stapleBindLowFlatPrice;
      } else {
        return stapleBindMinimumCharge + (Math.ceil(quantity / 1000) - 1) * stapleBindIncrementPrice;
      }
    }
  }

  public calcDigitalStapleBindCost(
    option: boolean,
    quantity: number,
    stapleBindMinimumCharge: number,
    stapleBindPricePerPiece: number
  ): number {
    if (!option) {
      return 0;
    }

    console.log('calcDigitalStapleBindCost quantity', quantity);
    console.log('calcDigitalStapleBindCost stapleBindPricePerPiece', stapleBindPricePerPiece);

    const multiplicationResult = math.multiply(stapleBindPricePerPiece, quantity);

    console.log('calcDigitalStapleBindCost multiplicationResult', multiplicationResult);

    if (multiplicationResult < stapleBindMinimumCharge) {
      return stapleBindMinimumCharge;
    } else {
      return multiplicationResult;
    }
  }

  public calcHardCoverCost(
    option: boolean,
    quantity: number,
    totalDesigns: number,
    actualUpsPerPaper: number,
    colorname: string,
    threadsewMinimumCharge: number,
    perfectBindThreadsewPerSectionCharge: number,
    perfectBindBindingCharge: number,
    bookUps: number,
    cut: number
  ): number {
    const e111 = this.ThreadsewPriceCountWithOutLow(
      totalDesigns,
      actualUpsPerPaper,
      quantity,
      colorname,
      perfectBindThreadsewPerSectionCharge,
      perfectBindBindingCharge,
      bookUps,
      cut
    );
    if (!option) {
      return 0;
    }
    if (e111 < threadsewMinimumCharge) {
      return threadsewMinimumCharge;
    }
    return e111;
  }

  public calcDigitalHardCoverCost(
    option: boolean,
    quantity: number,
    threadsewMinimumCharge: number,
    threadsewPricePerPiece: number
  ): number {
    if (!option) {
      return 0;
    }

    if (math.multiply(threadsewPricePerPiece, quantity) < threadsewMinimumCharge) {
      return threadsewMinimumCharge;
    } else {
      return math.multiply(threadsewPricePerPiece, quantity);
    }
  }

  //e111
  public ThreadsewPriceCountWithOutLow(
    totalDesigns: number,
    actualUpsPerPaper: number,
    quantity: number,
    colorname: string,
    perfectBindThreadsewPerSectionCharge: number,
    perfectBindBindingCharge: number,
    bookUps: number,
    cut: number
  ): number {
    const E107 = this.twoSidePrint(colorname);
    let val1 = 0;
    if (cut == 1) {
      if (E107) {
        val1 = Math.ceil(totalDesigns / bookUps / 2) / 2;
      } else {
        val1 = Math.ceil(totalDesigns / bookUps);
      }
    } else {
      if (E107) {
        val1 = Math.ceil(totalDesigns / (bookUps * 2));
      } else {
        val1 = Math.ceil(totalDesigns / bookUps);
      }
    }

    const result = (val1 * perfectBindThreadsewPerSectionCharge + perfectBindBindingCharge) * quantity;
    return result;
  }

  // ---------------- Calculate Offset lamination (A12) ----------------
  // Calculate quantity (both front end back)
  // E14 = quantity front + quantity back
  public calculateQuantity(
    paperNeeded: number,
    amount: number,
    finishedProductTotalQuantity: number,
    option: boolean,
    cut: number
  ): number {
    if (cut === 1) {
      if (option) {
        return paperNeeded * (amount / finishedProductTotalQuantity) * 2;
      } else {
        return 0;
      }
    } else {
      if (option) {
        return paperNeeded * (amount / finishedProductTotalQuantity);
      } else {
        return 0;
      }
    }
  }

  // Calculate Testing Quantity (E15)
  public TestingQuantity(offsetLamTestingQuantity: number, cut: number): number {
    if (cut === 1) {
      return offsetLamTestingQuantity / 2;
    }
    return offsetLamTestingQuantity;
  }

  public calculateOffsetLamination(
    paperNeeded: number,
    amountFront: number,
    optionFront: boolean,
    amountBack: number,
    optionBack: boolean,
    quantityPerDesign: number,
    offsetLamTestingQuantity: number,
    cut: number,
    squareInches: number,
    offsetLamPrice: number,
    offsetLamMinimumCost: number,
    finishedProductTotalQuantity: number
  ): number {
    const e12 = this.calculateQuantity(paperNeeded, amountFront, finishedProductTotalQuantity, optionFront, cut);
    const e13 = this.calculateQuantity(paperNeeded, amountBack, finishedProductTotalQuantity, optionBack, cut);
    const e14 = add(e12, e13);
    const e15 = offsetLamTestingQuantity;
    const e16 = add(e15, e14);

    let result: number;

    if (e14 === 0) {
      result = 0;
    } else {
      if (cut === 0) {
        if (e16 * squareInches * offsetLamPrice < offsetLamMinimumCost) {
          result = offsetLamMinimumCost;
        } else {
          result = e16 * squareInches * offsetLamPrice;
        }
      } else if (cut === 1) {
        if (e16 * (squareInches / 2) * offsetLamPrice < offsetLamMinimumCost) {
          result = offsetLamMinimumCost;
        } else {
          result = e16 * (squareInches / 2) * offsetLamPrice;
        }
      }
    }

    return result;
  }

  public calcDigitalCompanyLamination(
    paperNeeded: number,
    optionFront: boolean,
    optionBack: boolean,
    digitalCompanyLaminationPricePerPiece: number,
    digitalCompanyLaminationMinimumCost: number
  ): number {
    let resultFront;
    let resultBack;
    if (optionFront) {
      resultFront = paperNeeded * digitalCompanyLaminationPricePerPiece;
      if (resultFront < digitalCompanyLaminationMinimumCost) {
        resultFront = digitalCompanyLaminationMinimumCost;
      }
    }

    if (optionBack) {
      resultBack = paperNeeded * digitalCompanyLaminationPricePerPiece;
      if (resultBack < digitalCompanyLaminationMinimumCost) {
        resultBack = digitalCompanyLaminationMinimumCost;
      }
    }

    let finalResult;
    if (optionFront && !optionBack) {
      finalResult = resultFront;
    } else if (optionFront && optionBack) {
      finalResult = math.add(resultFront, resultBack);
    } else if (!optionFront && optionBack) {
      finalResult = resultBack;
    } else {
      finalResult = 0;
    }

    console.log('calcDigitalCompanyLamination finalResult', finalResult);
    return finalResult;
  }

  // ---------------- Calculate Digital lamination (A10) ----------------
  // Calculate A10
  public calculateDigitalLamination(
    amountFront: number,
    optionFront: boolean,
    amountBack: number,
    optionBack: boolean,
    upsAfterCut: number,
    digitalLamTestingThreshold: number,
    digitalLamTestingQuantity: number,
    digitalLam1SidePrice: number,
    digitalLamMinimumCost: number
  ): number {
    let e3: number;
    if (optionFront) {
      e3 = amountFront / upsAfterCut;
    } else {
      e3 = 0;
    }

    let e4: number;
    if (optionBack) {
      e4 = amountBack / upsAfterCut;
    } else {
      e4 = 0;
    }

    const e5 = add(e3, e4);

    let e6: number;
    if (e5 < digitalLamTestingThreshold) {
      e6 = 0;
    } else {
      e6 = digitalLamTestingQuantity;
    }

    const e7 = add(e5, e6);
    let result: number;

    if (e7 === 0) {
      result = 0;
    } else {
      if (e7 * digitalLam1SidePrice < digitalLamMinimumCost) {
        result = digitalLamMinimumCost;
      } else {
        result = e7 * digitalLam1SidePrice;
      }
    }

    return result;
  }

  // ---------------- Calculate Offset Lamination A3 Size (A14) ----------------
  // Calculate A14
  public calculateOffsetLaminationA3(
    amountFront: number,
    optionFront: boolean,
    amountBack: number,
    optionBack: boolean,
    upsAfterCut: number,
    offsetLamTestingQuantity: number,
    a3SquareInches: number,
    offsetLamPrice: number,
    offsetLamMinimumCost: number
  ): number {
    let e3: number;
    if (optionFront) {
      e3 = amountFront / upsAfterCut;
    } else {
      e3 = 0;
    }

    let e4: number;
    if (optionBack) {
      e4 = amountBack / upsAfterCut;
    } else {
      e4 = 0;
    }

    const e20 = e3;
    const e21 = e4;
    const e22 = add(e21, e20);
    const e23 = offsetLamTestingQuantity;
    const e24 = add(e23, e22);
    let result: number;

    if (e22 === 0) {
      result = 0;
    } else {
      if (e24 * a3SquareInches * offsetLamPrice < offsetLamMinimumCost) {
        result = offsetLamMinimumCost;
      } else {
        result = e24 * a3SquareInches * offsetLamPrice;
      }
    }

    return result;
  }

  // ---------------- Calculate Water Based A3 Size (B17) ----------------
  public calculateWaterBasedA3(
    amountFront: number,
    optionFront: boolean,
    amountBack: number,
    optionBack: boolean,
    upsAfterCut: number,
    waterBasedPrice: number,
    waterBasedTestingQuantity: number,
    a3SquareInches: number,
    waterBasedMinimumCost: number,
    cut: number
  ): number {
    let j28: number;
    let j29: number;

    if (optionFront) {
      j28 = amountFront / upsAfterCut;
    } else {
      j28 = 0;
    }

    if (optionBack) {
      j29 = amountBack / upsAfterCut;
    } else {
      j29 = 0;
    }

    // total quantity
    const j30 = add(j29, j28);

    const j31 = waterBasedTestingQuantity;
    // new total quantity
    const j32 = add(j30, j31);
    let result: number;

    if (j30 === 0) {
      result = 0;
    } else {
      if (j32 * a3SquareInches * waterBasedPrice > waterBasedMinimumCost) {
        result = waterBasedMinimumCost;
      } else {
        result = j32 * a3SquareInches * waterBasedPrice;
      }
    }

    return result;
  }

  // ---------------- Calculate Water Based Offset Size (A17) ----------------
  public calculateWaterBasedOffsetSize(
    paperNeeded: number,
    amountFront: number,
    optionFront: boolean,
    amountBack: number,
    optionBack: boolean,
    waterBasedPrice: number,
    waterBasedTestingQuantity: number,
    squareInches: number,
    waterBasedMinimumCost: number,
    cut: number,
    quantityPerDesign: number,
    finishedProductTotalQuantity: number
  ): number {
    const e28 = this.calculateQuantity(paperNeeded, amountFront, finishedProductTotalQuantity, optionFront, cut);
    const e29 = this.calculateQuantity(paperNeeded, amountBack, finishedProductTotalQuantity, optionBack, cut);
    const e30 = add(e28, e29);
    const e31 = waterBasedTestingQuantity;
    const e32 = add(e30, e31);
    let result: number;

    if (e30 === 0) {
      result = 0;
    } else {
      if (cut === 0) {
        if (e32 * squareInches * waterBasedPrice < waterBasedMinimumCost) {
          result = waterBasedMinimumCost;
        } else {
          result = e32 * squareInches * waterBasedPrice;
        }
      } else if (cut === 1) {
        if (e32 * (squareInches / 2) * waterBasedPrice < waterBasedMinimumCost) {
          result = waterBasedMinimumCost;
        } else {
          result = e32 * (squareInches / 2) * waterBasedPrice;
        }
      }
    }

    return result;
  }

  // ---------------- Calculate UV Offset Size (A20) ----------------
  public calculateUVOffsetSize(
    paperNeeded: number,
    amountFront: number,
    optionFront: boolean,
    amountBack: number,
    optionBack: boolean,
    quantityPerDesign: number,
    cut: number,
    uvTestingQuantity: number,
    customerSupplyPaper: boolean,
    squareInches: number,
    uvCardPrice: number,
    uvPaperPrice: number,
    uvMinimumCost: number,
    finishedProductTotalQuantity: number,
    isCard: boolean
  ): number {
    const e36 = this.calculateQuantity(paperNeeded, amountFront, finishedProductTotalQuantity, optionFront, cut);
    const e37 = this.calculateQuantity(paperNeeded, amountBack, finishedProductTotalQuantity, optionBack, cut);
    const e38 = add(e37, e36);
    const e39 = uvTestingQuantity;
    const e40 = add(e39, e38);
    let result: number;

    if (e38 === 0) {
      result = 0;
    } else {
      if (cut === 0) {
        if (isCard) {
          if (e40 * squareInches * uvCardPrice < uvMinimumCost) {
            result = uvMinimumCost;
          } else {
            result = e40 * squareInches * uvCardPrice;
          }
        } else {
          if (e40 * squareInches * uvPaperPrice < uvMinimumCost) {
            result = uvMinimumCost;
          } else {
            result = e40 * squareInches * uvPaperPrice;
          }
        }
      } else if (cut === 1) {
        if (isCard) {
          if (e40 * (squareInches / 2) * uvCardPrice < uvMinimumCost) {
            result = uvMinimumCost;
          } else {
            result = e40 * (squareInches / 2) * uvCardPrice;
          }
        } else {
          if (e40 * (squareInches / 2) * uvPaperPrice < uvMinimumCost) {
            result = uvMinimumCost;
          } else {
            result = e40 * (squareInches / 2) * uvPaperPrice;
          }
        }
      }
    }

    return result;
  }

  // ---------------- Calculate Varnish A3 Size (B23) ----------------
  public calculateVarnishdA3(
    amountFront: number,
    optionFront: boolean,
    amountBack: number,
    optionBack: boolean,
    upsAfterCut: number,
    varnishPrice: number,
    varnishTestingQuantity: number,
    a3SquareInches: number,
    varnishMinimumCost: number,
    cut: number
  ): number {
    let j44 = 0;
    let j45 = 0;

    if (optionFront) {
      j44 = amountFront / upsAfterCut;
    }
    if (optionBack) {
      j45 = amountBack / upsAfterCut;
    }

    const j46 = add(j45, j44);
    const j47 = varnishTestingQuantity;
    const j48 = add(j47, j46);
    let result: number;

    if (j46 === 0) {
      result = 0;
    } else {
      if (j48 * a3SquareInches * varnishPrice < varnishMinimumCost) {
        result = varnishMinimumCost;
      } else {
        result = j48 * a3SquareInches * varnishPrice;
      }
    }
    return result;
  }

  // ---------------- Calculate Varnish Offset Size (A23) ----------------
  public calculateVarnishOffset(
    paperNeeded: number,
    amountFront: number,
    optionFront: boolean,
    amountBack: number,
    optionBack: boolean,
    varnishPrice: number,
    varnishTestingQuantity: number,
    squareInches: number,
    varnishMinimumCost: number,
    cut: number,
    quantityPerDesign: number,
    finishedProductTotalQuantity: number
  ): number {
    const e44 = this.calculateQuantity(paperNeeded, amountFront, finishedProductTotalQuantity, optionFront, cut);
    const e45 = this.calculateQuantity(paperNeeded, amountBack, finishedProductTotalQuantity, optionBack, cut);
    const e46 = add(e45, e44);
    const e47 = varnishTestingQuantity;
    const e48 = add(e47, e46);
    let result: number;

    if (e46 === 0) {
      result = 0;
    } else {
      if (cut === 0) {
        if (e48 * squareInches * varnishPrice < varnishMinimumCost) {
          result = varnishMinimumCost;
        } else {
          result = ((e48 * squareInches) / 2) * varnishPrice;
        }
      } else if (cut === 1) {
        if (e48 * squareInches * varnishPrice < varnishMinimumCost) {
          result = varnishMinimumCost;
        } else {
          result = ((e48 * squareInches) / 2) * varnishPrice;
        }
      }
    }
    return result;
  }

  // Calculate Spot UV offset size (A26)
  public calculateSpotUVOffsetSize(
    inchesSquareAfterCuthidden: number,
    spotUVTestingQuantity: number,
    spotUVPrice: number,
    minimumCost: number,
    cut: number,
    optionFront: boolean,
    optionBack: boolean,
    amountFront: number,
    amountBack: number,
    lengthAfterCut: number,
    finishedProductTotalQuantity: number
  ): number {
    const E51 = this.calculateSpotUVQuantity(cut, optionFront, lengthAfterCut, amountFront, finishedProductTotalQuantity);
    const E52 = this.calculateSpotUVQuantity(cut, optionBack, lengthAfterCut, amountBack, finishedProductTotalQuantity);
    const E53 = add(E51, E52);
    const E54 = spotUVTestingQuantity;
    const E55 = add(E53, E54);
    const B4 = inchesSquareAfterCuthidden;

    if (E53 === 0) {
      return 0;
    }

    let cost: number;
    if (cut === 0) {
      cost = E55 * B4 * spotUVPrice;
    } else {
      cost = ((E55 * B4) / 2) * spotUVPrice;
    }

    if (cost < minimumCost) {
      return minimumCost;
    }

    return cost;
  }

  public calculateDigitalSpotUVPrice(optionFront: boolean, optionBack: boolean) {}

  // ---------------- Calculate Spot UV price ----------------
  public calculateSpotUVPrice(
    inchesSquareAfterCuthidden: number,
    spotUVTestingQuantity: number,
    printTechnique: string,
    spotUVPrice: number,
    minimumCost: number,
    cut: number,
    optionFront: boolean,
    optionBack: boolean,
    amountFront: number,
    amountBack: number,
    lengthAfterCut: number,
    finishedProductTotalQuantity: number,
    companyCategory: string
  ): number {
    const A26 = this.calculateSpotUVOffsetSize(
      inchesSquareAfterCuthidden,
      spotUVTestingQuantity,
      spotUVPrice,
      minimumCost,
      cut,
      optionFront,
      optionBack,
      amountFront,
      amountBack,
      lengthAfterCut,
      finishedProductTotalQuantity
    );

    if (printTechnique === 'Digital Print') {
      return 0;
    } else {
      return A26;
    }
  }

  foldCost(option: boolean, folds: number, fold_quantity: number, foldChargePer1000sm2: number, foldChargePer1000gr2: number): number {
    if (option) {
      let result: number;
      if (folds < 3) {
        result = Math.ceil(fold_quantity / 1000) * foldChargePer1000sm2;
      } else {
        result = Math.ceil(fold_quantity / 1000) * foldChargePer1000gr2;
      }
      return result;
    } else {
      return 0;
    }
  }
}
