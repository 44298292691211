import axios from 'axios';

import buildPaginationQueryOpts from '@/shared/sort/sorts';

import { ICustomer } from '@/shared/model/customer.model';

const baseApiUrl = 'api/customers';

const companyBaseApiUrl = 'api/customers-by-company';

export class CustomerService {
  public findCustomerByIdAndCompany(customerId: number): Promise<ICustomer> {
    return new Promise<ICustomer>((resolve, reject) => {
      axios
        .get(`${companyBaseApiUrl}/${customerId}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public find(id: number): Promise<ICustomer> {
    return new Promise<ICustomer>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public findByCompany(): Promise<ICustomer[]> {
    return new Promise<ICustomer[]>((resolve, reject) => {
      axios
        .get(`${companyBaseApiUrl}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieve(paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl + `?${buildPaginationQueryOpts(paginationQuery)}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveByCompany(paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(companyBaseApiUrl + `?${buildPaginationQueryOpts(paginationQuery)}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveByCompanyUnpaged(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(companyBaseApiUrl + `?unpaged=true`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public delete(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public deleteByCompany(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${companyBaseApiUrl}/${id}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public createByCompany(entity: ICustomer): Promise<ICustomer> {
    return new Promise<ICustomer>((resolve, reject) => {
      axios
        .post(`${companyBaseApiUrl}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public create(entity: ICustomer): Promise<ICustomer> {
    return new Promise<ICustomer>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public update(entity: ICustomer): Promise<ICustomer> {
    return new Promise<ICustomer>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public updateByCompany(entity: ICustomer): Promise<ICustomer> {
    return new Promise<ICustomer>((resolve, reject) => {
      axios
        .put(`${companyBaseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public partialUpdate(entity: ICustomer): Promise<ICustomer> {
    return new Promise<ICustomer>((resolve, reject) => {
      axios
        .patch(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public partialUpdateByCompany(entity: ICustomer, companyId: number): Promise<ICustomer> {
    return new Promise<ICustomer>((resolve, reject) => {
      axios
        .patch(`${companyBaseApiUrl}/${companyId}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public searchCustomersByCompany(keyword: string): Promise<ICustomer[]> {
    return new Promise<ICustomer[]>((resolve, reject) => {
      axios
        .get(`${companyBaseApiUrl}/search`, {
          params: { keyword },
        })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
