import { Module } from 'vuex';

export interface BleedPresetStore {
  bleedWidthMm: number;
  bleedLengthMm: number;
}

export const defaultBleedPresetStore: BleedPresetStore = {
  bleedWidthMm: 1,
  bleedLengthMm: 1,
};

export const bleedPresetStore: Module<BleedPresetStore, any> = {
  state: {
    ...defaultBleedPresetStore,
  },

  getters: {
    bleedWidthMm: state => state.bleedWidthMm,
    bleedLengthMm: state => state.bleedLengthMm,
  },

  mutations: {
    setBleedWidthMm(state, bleedWidthMm) {
      state.bleedWidthMm = bleedWidthMm;
    },
    setBleedLengthMm(state, bleedLengthMm) {
      state.bleedLengthMm = bleedLengthMm;
    },
  },
};
