import axios from 'axios';

import buildPaginationQueryOpts from '@/shared/sort/sorts';

import { IMachine } from '@/shared/model/machine.model';

const baseApiUrl = 'api/machines';

const machineByCompanyUrl = '/api/machines-by-company';

export default class MachineService {
  public find(id: number): Promise<IMachine> {
    return new Promise<IMachine>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public findByCompany(id: number): Promise<IMachine> {
    return new Promise<IMachine>((resolve, reject) => {
      axios
        .get(`${machineByCompanyUrl}/${id}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieve(paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl + `?${buildPaginationQueryOpts(paginationQuery)}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveByCompany(paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(machineByCompanyUrl + `?${buildPaginationQueryOpts(paginationQuery)}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public delete(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public deleteByCompany(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${machineByCompanyUrl}/${id}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public create(entity: IMachine): Promise<IMachine> {
    return new Promise<IMachine>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public createByCompany(entity: IMachine): Promise<IMachine> {
    return new Promise<IMachine>((resolve, reject) => {
      axios
        .post(`${machineByCompanyUrl}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public update(entity: IMachine): Promise<IMachine> {
    return new Promise<IMachine>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public updateByCompany(entity: IMachine): Promise<IMachine> {
    return new Promise<IMachine>((resolve, reject) => {
      axios
        .put(`${machineByCompanyUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public partialUpdate(entity: IMachine): Promise<IMachine> {
    return new Promise<IMachine>((resolve, reject) => {
      axios
        .patch(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
