import axios from 'axios';

import buildPaginationQueryOpts from '@/shared/sort/sorts';

import { IUserCompany, UserCompany } from '@/shared/model/user-company.model';

const baseApiUrl = 'api/user-companies';

const userCompaniesWithAuthoritiesByCompanyUrl = '/api/user-companies-by-company-with-authorities';

export default class UserCompanyService {
  updateUserCompany(userCompany: UserCompany) {
    return new Promise<IUserCompany>((resolve, reject) => {
      axios
        .put(`${userCompaniesWithAuthoritiesByCompanyUrl}/${userCompany.id}`, userCompany)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public find(id: number): Promise<IUserCompany> {
    return new Promise<IUserCompany>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieve(paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl + `?${buildPaginationQueryOpts(paginationQuery)}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveUserCompaniesWithAuthoritiesByCompany(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(userCompaniesWithAuthoritiesByCompanyUrl)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveUserCompaniesWithAuthoritiesByCompanyAndAuthorities(authorities: string[]): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(userCompaniesWithAuthoritiesByCompanyUrl + '?authorities=' + authorities.join(','))
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public delete(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public create(entity: IUserCompany): Promise<IUserCompany> {
    return new Promise<IUserCompany>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public update(entity: IUserCompany): Promise<IUserCompany> {
    return new Promise<IUserCompany>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public partialUpdate(entity: IUserCompany): Promise<IUserCompany> {
    return new Promise<IUserCompany>((resolve, reject) => {
      axios
        .patch(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
