import { Authority } from '@/shared/security/authority';
import { component } from 'vue/types/umd';
/* tslint:disable */
// prettier-ignore
const Entities = () => import('@/entities/entities.vue');

const PaperCategory = () => import('@/entities/paper-category/paper-category.vue');
const PaperCategoryUpdate = () => import('@/entities/paper-category/paper-category-update.vue');
const PaperCategoryDetails = () => import('@/entities/paper-category/paper-category-details.vue');

const Company = () => import('@/entities/company/company.vue');
const CompanyUpdate = () => import('@/entities/company/company-update.vue');
const CompanyDetails = () => import('@/entities/company/company-details.vue');

const ProductCategory = () => import('@/entities/product-category/product-category.vue');
const ProductCategoryUpdate = () => import('@/entities/product-category/product-category-update.vue');
const ProductCategoryDetails = () => import('@/entities/product-category/product-category-details.vue');

const PaperGrammage = () => import('@/entities/paper-grammage/paper-grammage.vue');
const PaperGrammageUpdate = () => import('@/entities/paper-grammage/paper-grammage-update.vue');
const PaperGrammageDetails = () => import('@/entities/paper-grammage/paper-grammage-details.vue');

const PaperType = () => import('@/entities/paper-type/paper-type.vue');
const PaperTypeUpdate = () => import('@/entities/paper-type/paper-type-update.vue');
const PaperTypeDetails = () => import('@/entities/paper-type/paper-type-details.vue');

const Quote = () => import('@/entities/quote/quote.vue');
const QuoteUpdate = () => import('@/entities/quote/quote-update.vue');
const QuoteDetails = () => import('@/entities/quote/quote-details.vue');

const LineItem = () => import('@/entities/line-item/line-item.vue');
const LineItemUpdate = () => import('@/entities/line-item/line-item-update.vue');
const LineItemDetails = () => import('@/entities/line-item/line-item-details.vue');

const Pricing = () => import('@/entities/pricing/pricing.vue');
const PricingUpdate = () => import('@/entities/pricing/pricing-update.vue');
const PricingDetails = () => import('@/entities/pricing/pricing-details.vue');

const FinishingType = () => import('@/entities/finishing-type/finishing-type.vue');
const FinishingTypeUpdate = () => import('@/entities/finishing-type/finishing-type-update.vue');
const FinishingTypeDetails = () => import('@/entities/finishing-type/finishing-type-details.vue');

const Product = () => import('@/entities/product/product.vue');
const ProductUpdate = () => import('@/entities/product/product-update.vue');
const ProductDetails = () => import('@/entities/product/product-details.vue');

const Machine = () => import('@/entities/machine/machine.vue');
const MachineUpdate = () => import('@/entities/machine/machine-update.vue');
const MachineDetails = () => import('@/entities/machine/machine-details.vue');

const PlateColor = () => import('@/entities/plate-color/plate-color.vue');
const PlateColorUpdate = () => import('@/entities/plate-color/plate-color-update.vue');
const PlateColorDetails = () => import('@/entities/plate-color/plate-color-details.vue');

const FinishingProperty = () => import('@/entities/finishing-property/finishing-property.vue');
const FinishingPropertyUpdate = () => import('@/entities/finishing-property/finishing-property-update.vue');
const FinishingPropertyDetails = () => import('@/entities/finishing-property/finishing-property-details.vue');

const BleedPreset = () => import('@/entities/bleed-preset/bleed-preset.vue');
const BleedPresetUpdate = () => import('@/entities/bleed-preset/bleed-preset-update.vue');
const BleedPresetDetails = () => import('@/entities/bleed-preset/bleed-preset-details.vue');

const Notification = () => import('@/entities/notification/notification.vue');
const NotificationUpdate = () => import('@/entities/notification/notification-update.vue');
const NotificationDetails = () => import('@/entities/notification/notification-details.vue');

const TaxRate = () => import('@/entities/tax-rate/tax-rate.vue');
const TaxRateUpdate = () => import('@/entities/tax-rate/tax-rate-update.vue');
const TaxRateDetails = () => import('@/entities/tax-rate/tax-rate-details.vue');

const TaxClass = () => import('@/entities/tax-class/tax-class.vue');
const TaxClassUpdate = () => import('@/entities/tax-class/tax-class-update.vue');
const TaxClassDetails = () => import('@/entities/tax-class/tax-class-details.vue');

const UserCompany = () => import('@/entities/user-company/user-company.vue');
const UserCompanyUpdate = () => import('@/entities/user-company/user-company-update.vue');
const UserCompanyDetails = () => import('@/entities/user-company/user-company-details.vue');

// prettier-ignore
const SubscribtionPackage = () => import('@/entities/subscribtion-package/subscribtion-package.vue');
// prettier-ignore
const SubscribtionPackageUpdate = () => import('@/entities/subscribtion-package/subscribtion-package-update.vue');
// prettier-ignore
const SubscribtionPackageDetails = () => import('@/entities/subscribtion-package/subscribtion-package-details.vue');
// prettier-ignore
const SubscribtionFeature = () => import('@/entities/subscribtion-feature/subscribtion-feature.vue');
// prettier-ignore
const SubscribtionFeatureUpdate = () => import('@/entities/subscribtion-feature/subscribtion-feature-update.vue');
// prettier-ignore
const SubscribtionFeatureDetails = () => import('@/entities/subscribtion-feature/subscribtion-feature-details.vue');
// prettier-ignore
const Subscribtion = () => import('@/entities/subscribtion/subscribtion.vue');
// prettier-ignore
const SubscribtionUpdate = () => import('@/entities/subscribtion/subscribtion-update.vue');
// prettier-ignore
const SubscribtionDetails = () => import('@/entities/subscribtion/subscribtion-details.vue');
// prettier-ignore
const Quota = () => import('@/entities/quota/quota.vue');
// prettier-ignore
const QuotaUpdate = () => import('@/entities/quota/quota-update.vue');
// prettier-ignore
const QuotaDetails = () => import('@/entities/quota/quota-details.vue');

const AuthorityMarkupPercentageListComponent = () => import('@/entities/authority-markup-percentage/index.vue');

const AuthorityMarkupPercentageCreate = () => import('@/entities/authority-markup-percentage/create.vue');

const AuthorityMarkupPercentageUpdate = () => import('@/entities/authority-markup-percentage/create.vue');

const CompanyCategory = () => import('@/entities/company-category/index.vue');

const CompanyCategoryCreate = () => import('@/entities/company-category/create.vue');

const CompanyCategoryUpdate = () => import('@/entities/company-category/create.vue');

const Transaction = () => import('@/entities/transaction/transaction.vue');

const TransactionDetails = () => import('@/entities/transaction/transaction-details.vue');

const TransactionCreate = () => import('@/entities/transaction/transaction-update.vue');

const TransactionUpdate = () => import('@/entities/transaction/transaction-update.vue');

// jhipster-needle-add-entity-to-router-import - JHipster will import entities to the router here

export default {
  path: '/',
  component: Entities,
  children: [
    {
      path: 'admin/paper-category',
      name: 'PaperCategory',
      component: PaperCategory,
      meta: {
        title: 'TapQuote Admin | Paper Categories',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: 'admin/paper-category/new',
      name: 'PaperCategoryCreate',
      component: PaperCategoryUpdate,
      meta: {
        title: 'TapQuote Admin | Create Paper Category',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: 'admin/paper-category/:paperCategoryId/edit',
      name: 'PaperCategoryEdit',
      component: PaperCategoryUpdate,
      meta: {
        title: 'TapQuote Admin | Edit Paper Category',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: 'admin/paper-category/:paperCategoryId/view',
      name: 'PaperCategoryView',
      component: PaperCategoryDetails,
      meta: {
        title: 'TapQuote Admin | Paper Category Details',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: 'admin/company',
      name: 'Company',
      component: Company,
      meta: {
        title: 'TapQuote Admin | Companies',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: 'admin/company/new',
      name: 'CompanyCreate',
      component: CompanyUpdate,
      meta: {
        title: 'TapQuote Admin | Create Company',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: 'admin/company/:companyId/edit',
      name: 'CompanyEdit',
      component: CompanyUpdate,
      meta: {
        title: 'TapQuote Admin | Edit Company',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: 'admin/company/:companyId/view',
      name: 'CompanyView',
      component: CompanyDetails,
      meta: {
        title: 'TapQuote Admin | Company Details',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: 'admin/product-category',
      name: 'ProductCategory',
      component: ProductCategory,
      meta: {
        title: 'TapQuote Admin | Product Categories',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: 'admin/product-category/new',
      name: 'ProductCategoryCreate',
      component: ProductCategoryUpdate,
      meta: {
        title: 'TapQuote Admin | Create Product Category',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: 'admin/product-category/:productCategoryId/edit',
      name: 'ProductCategoryEdit',
      component: ProductCategoryUpdate,
      meta: {
        title: 'TapQuote Admin | Update Product Category',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: 'admin/product-category/:productCategoryId/view',
      name: 'ProductCategoryView',
      component: ProductCategoryDetails,
      meta: {
        title: 'TapQuote Admin | Product Category Details',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: 'admin/paper-grammage',
      name: 'PaperGrammage',
      component: PaperGrammage,
      meta: {
        title: 'TapQuote Admin | Paper Grammages',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: 'admin/paper-grammage/new',
      name: 'PaperGrammageCreate',
      component: PaperGrammageUpdate,
      meta: {
        title: 'TapQuote Admin | Create Paper Grammage',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: 'admin/paper-grammage/:paperGrammageId/edit',
      name: 'PaperGrammageEdit',
      component: PaperGrammageUpdate,
      meta: {
        title: 'TapQuote Admin | Edit Paper Grammage',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: 'admin/paper-grammage/:paperGrammageId/view',
      name: 'PaperGrammageView',
      component: PaperGrammageDetails,
      meta: {
        title: 'TapQuote Admin | Paper Grammage Details',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: 'admin/paper-type',
      name: 'PaperType',
      component: PaperType,
      meta: {
        title: 'TapQuote Admin | Create Paper Type',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: 'admin/paper-type/new',
      name: 'PaperTypeCreate',
      component: PaperTypeUpdate,
      meta: {
        title: 'TapQuote Admin | Create Paper Type',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: 'admin/paper-type/:paperTypeId/edit',
      name: 'PaperTypeEdit',
      component: PaperTypeUpdate,
      meta: {
        title: 'TapQuote Admin | Edit Paper Type',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: 'admin/paper-type/:paperTypeId/view',
      name: 'PaperTypeView',
      component: PaperTypeDetails,
      meta: {
        title: 'TapQuote Admin | Paper Type Details',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: 'admin/quote',
      name: 'Quote',
      component: Quote,
      meta: {
        title: 'TapQuote Admin | Quotes',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: 'admin/quote/new',
      name: 'QuoteCreate',
      component: QuoteUpdate,
      meta: {
        title: 'TapQuote Admin | Create Quote',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: 'admin/quote/:quoteId/edit',
      name: 'QuoteEdit',
      component: QuoteUpdate,
      meta: {
        title: 'TapQuote Admin | Edit Quote',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: 'admin/quote/:quoteId/view',
      name: 'QuoteView',
      component: QuoteDetails,
      meta: {
        title: 'TapQuote Admin | Quote Details',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: 'admin/line-item',
      name: 'LineItem',
      component: LineItem,
      meta: {
        title: 'TapQuote Admin | Line Items',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: 'admin/line-item/new',
      name: 'LineItemCreate',
      component: LineItemUpdate,
      meta: {
        title: 'TapQuote Admin | Create Line Item',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: 'admin/line-item/:lineItemId/edit',
      name: 'LineItemEdit',
      component: LineItemUpdate,
      meta: {
        title: 'TapQuote Admin | Edit Line Item',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: 'admin/line-item/:lineItemId/view',
      name: 'LineItemView',
      component: LineItemDetails,
      meta: {
        title: 'TapQuote Admin | Line Item Details',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: 'admin/pricing',
      name: 'Pricing',
      component: Pricing,
      meta: {
        title: 'TapQuote Admin | Pricings',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: 'admin/pricing/new',
      name: 'PricingCreate',
      component: PricingUpdate,
      meta: {
        title: 'TapQuote Admin | Create Pricing',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: 'admin/pricing/:pricingId/edit',
      name: 'PricingEdit',
      component: PricingUpdate,
      meta: {
        title: 'TapQuote Admin | Update Pricing',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: 'admin/pricing/:pricingId/view',
      name: 'PricingView',
      component: PricingDetails,
      meta: {
        title: 'TapQuote Admin | Pricing Details',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: 'admin/finishing-type',
      name: 'FinishingType',
      component: FinishingType,
      meta: {
        title: 'TapQuote Admin | Creating Finishing Type',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: 'admin/finishing-type/new',
      name: 'FinishingTypeCreate',
      component: FinishingTypeUpdate,
      meta: {
        title: 'TapQuote Admin | Create Finishing Type',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: 'admin/finishing-type/:finishingTypeId/edit',
      name: 'FinishingTypeEdit',
      component: FinishingTypeUpdate,
      meta: {
        title: 'TapQuote Admin | Update Finishing Type',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: 'admin/finishing-type/:finishingTypeId/view',
      name: 'FinishingTypeView',
      component: FinishingTypeDetails,
      meta: {
        title: 'TapQuote Admin | Finishing Type Details',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: 'admin/product',
      name: 'Product',
      component: Product,
      meta: {
        title: 'TapQuote Admin | Products',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: 'admin/product/new',
      name: 'ProductCreate',
      component: ProductUpdate,
      meta: {
        title: 'TapQuote Admin | Create Product',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: 'admin/product/:productId/edit',
      name: 'ProductEdit',
      component: ProductUpdate,
      meta: {
        title: 'TapQuote Admin | Update Product',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: 'admin/product/:productId/view',
      name: 'ProductView',
      component: ProductDetails,
      meta: {
        title: 'TapQuote Admin | Product Details',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: 'admin/machine',
      name: 'Machine',
      component: Machine,
      meta: {
        title: 'TapQuote Admin | Machines',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: 'admin/machine/new',
      name: 'MachineCreate',
      component: MachineUpdate,
      meta: {
        title: 'TapQuote Admin | Create Machine',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: 'admin/machine/:machineId/edit',
      name: 'MachineEdit',
      component: MachineUpdate,
      meta: {
        title: 'TapQuote Admin | Edit Machine',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: 'admin/machine/:machineId/view',
      name: 'MachineView',
      component: MachineDetails,
      meta: {
        title: 'TapQuote Admin | Machine Details',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: 'admin/plate-color',
      name: 'PlateColor',
      component: PlateColor,
      meta: {
        title: 'TapQuote Admin | Plate Colors',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: 'admin/plate-color/new',
      name: 'PlateColorCreate',
      component: PlateColorUpdate,
      meta: {
        title: 'TapQuote Admin | Create Plate Color',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: 'admin/plate-color/:plateColorId/edit',
      name: 'PlateColorEdit',
      component: PlateColorUpdate,
      meta: {
        title: 'TapQuote Admin | Update Plate Color',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: 'admin/plate-color/:plateColorId/view',
      name: 'PlateColorView',
      component: PlateColorDetails,
      meta: {
        title: 'TapQuote Admin | Plate Color Details',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: 'admin/finishing-property',
      name: 'FinishingProperty',
      component: FinishingProperty,
      meta: {
        title: 'TapQuote Admin | Finishing Properties',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: 'admin/finishing-property/new',
      name: 'FinishingPropertyCreate',
      component: FinishingPropertyUpdate,
      meta: {
        title: 'TapQuote Admin | Create Finishing Property',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: 'admin/finishing-property/:finishingPropertyId/edit',
      name: 'FinishingPropertyEdit',
      component: FinishingPropertyUpdate,
      meta: {
        title: 'TapQuote Admin | Edit Finishing Property',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: 'admin/finishing-property/:finishingPropertyId/view',
      name: 'FinishingPropertyView',
      component: FinishingPropertyDetails,
      meta: {
        title: 'TapQuote Admin | Finishing Property Details',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: 'admin/bleed-preset',
      name: 'BleedPreset',
      component: BleedPreset,
      meta: {
        title: 'TapQuote Admin | Bleed Presets',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: 'admin/bleed-preset/new',
      name: 'BleedPresetCreate',
      component: BleedPresetUpdate,
      meta: {
        title: 'TapQuote Admin | Create Bleed Preset',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: 'admin/bleed-preset/:bleedPresetId/edit',
      name: 'BleedPresetEdit',
      component: BleedPresetUpdate,
      meta: {
        title: 'TapQuote Admin | Edit Bleed Preset',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: 'admin/bleed-preset/:bleedPresetId/view',
      name: 'BleedPresetView',
      component: BleedPresetDetails,
      meta: {
        title: 'TapQuote Admin | Bleed Preset Details',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: 'admin/notification',
      name: 'Notification',
      component: Notification,
      meta: {
        title: 'TapQuote Admin | Notifications',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: 'admin/notification/new',
      name: 'NotificationCreate',
      component: NotificationUpdate,
      meta: {
        title: 'TapQuote Admin | New Notification',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: 'admin/notification/:notificationId/edit',
      name: 'NotificationEdit',
      component: NotificationUpdate,
      meta: {
        title: 'TapQuote Admin | Edit Notification',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: 'admin/notification/:notificationId/view',
      name: 'NotificationView',
      component: NotificationDetails,
      meta: {
        title: 'TapQuote Admin | Notification Details',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: 'admin/tax-rate',
      name: 'TaxRate',
      component: TaxRate,
      meta: {
        title: 'TapQuote Admin | Tax Rates',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: 'admin/tax-rate/new',
      name: 'TaxRateCreate',
      component: TaxRateUpdate,
      meta: {
        title: 'TapQuote Admin | New Tax Rate',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: 'admin/tax-rate/:taxRateId/edit',
      name: 'TaxRateEdit',
      component: TaxRateUpdate,
      meta: {
        title: 'TapQuote Admin | Edit Tax Rate',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: 'admin/tax-rate/:taxRateId/view',
      name: 'TaxRateView',
      component: TaxRateDetails,
      meta: {
        title: 'TapQuote Admin | Tax Rate Details',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: 'admin/tax-class',
      name: 'TaxClass',
      component: TaxClass,
      meta: {
        title: 'TapQuote Admin | Tax Classes',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: 'admin/tax-class/new',
      name: 'TaxClassCreate',
      component: TaxClassUpdate,
      meta: {
        title: 'TapQuote Admin | Create Tax Class',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: 'admin/tax-class/:taxClassId/edit',
      name: 'TaxClassEdit',
      component: TaxClassUpdate,
      meta: {
        title: 'TapQuote Admin | Edit Tax Class',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: 'admin/tax-class/:taxClassId/view',
      name: 'TaxClassView',
      component: TaxClassDetails,
      meta: {
        title: 'TapQuote Admin | Tax Class Details',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: 'admin/user-company',
      name: 'UserCompany',
      component: UserCompany,
      meta: {
        title: 'TapQuote Admin | User Company List',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: 'admin/user-company/new',
      name: 'UserCompanyCreate',
      component: UserCompanyUpdate,
      meta: {
        title: 'TapQuote Admin | Create User Company',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: 'admin/user-company/:userCompanyId/edit',
      name: 'UserCompanyEdit',
      component: UserCompanyUpdate,
      meta: {
        title: 'TapQuote Admin | Edit User Company',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: 'admin/user-company/:userCompanyId/view',
      name: 'UserCompanyView',
      component: UserCompanyDetails,
      meta: {
        title: 'TapQuote Admin | User Company Details',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: 'admin/subscribtion-package',
      name: 'SubscribtionPackage',
      component: SubscribtionPackage,
      meta: {
        title: 'TapQuote Admin | Subscribtion Packages',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: 'admin/subscribtion-package/new',
      name: 'SubscribtionPackageCreate',
      component: SubscribtionPackageUpdate,
      meta: {
        title: 'TapQuote Admin | Create Subscription Package',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: 'admin/subscribtion-package/:subscribtionPackageId/edit',
      name: 'SubscribtionPackageEdit',
      component: SubscribtionPackageUpdate,
      meta: {
        title: 'TapQuote Admin | Edit Subscription Package',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: 'admin/subscribtion-package/:subscribtionPackageId/view',
      name: 'SubscribtionPackageView',
      component: SubscribtionPackageDetails,
      meta: {
        title: 'TapQuote Admin | Subscribtion Package Details',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: 'admin/subscribtion-feature',
      name: 'SubscribtionFeature',
      component: SubscribtionFeature,
      meta: {
        title: 'TapQuote Admin | Subscription Features',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: 'admin/subscribtion-feature/new',
      name: 'SubscribtionFeatureCreate',
      component: SubscribtionFeatureUpdate,
      meta: {
        title: 'TapQuote Admin | Create Subscription Feature',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: 'admin/subscribtion-feature/:subscribtionFeatureId/edit',
      name: 'SubscribtionFeatureEdit',
      component: SubscribtionFeatureUpdate,
      meta: {
        title: 'TapQuote Admin | Edit Subscription Feature',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: 'admin/subscribtion-feature/:subscribtionFeatureId/view',
      name: 'SubscribtionFeatureView',
      component: SubscribtionFeatureDetails,
      meta: {
        title: 'TapQuote Admin | Subscription Feature Details',
        authorities: [Authority.GROUP_ADMIN],
      },
    },

    {
      path: 'admin/subscribtion',
      name: 'Subscribtion',
      component: Subscribtion,
      meta: {
        title: 'TapQuote Admin | Subscriptions',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: 'admin/subscribtion/new',
      name: 'SubscribtionCreate',
      component: SubscribtionUpdate,
      meta: {
        title: 'TapQuote Admin | Create Subscription',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: 'admin/subscribtion/:subscribtionId/edit',
      name: 'SubscribtionEdit',
      component: SubscribtionUpdate,
      meta: {
        title: 'TapQuote Admin | Edit Subscription',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: 'admin/subscribtion/:subscribtionId/view',
      name: 'SubscribtionView',
      component: SubscribtionDetails,
      meta: {
        title: 'TapQuote Admin | Subscription Details',
        authorities: [Authority.GROUP_ADMIN],
      },
    },

    {
      path: 'admin/quota',
      name: 'Quota',
      component: Quota,
      meta: {
        title: 'TapQuote Admin | Quota List',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: 'admin/quota/new',
      name: 'QuotaCreate',
      component: QuotaUpdate,
      meta: {
        title: 'TapQuote Admin | Create Quota',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: 'admin/quota/:quotaId/edit',
      name: 'QuotaEdit',
      component: QuotaUpdate,
      meta: {
        title: 'TapQuote Admin | Update Quota',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: 'admin/quota/:quotaId/view',
      name: 'QuotaView',
      component: QuotaDetails,
      meta: {
        title: 'TapQuote Admin | Quota Details',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: '/admin/authority-markup-percentage',
      name: 'Authority Markup Percentage',
      component: AuthorityMarkupPercentageListComponent,
      meta: {
        title: 'TapQuote Admin | Authority Markup Percentage',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: '/admin/authority-markup-percentage/new',
      name: 'AuthorityMarkupPercentageCreate',
      component: AuthorityMarkupPercentageCreate,
      meta: {
        title: 'TapQuote Admin | Authority Markup Percentage Create',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: '/admin/authority-markup-percentage/:authorityMarkupPercentageId/edit',
      name: 'AuthorityMarkupPercentageEdit',
      component: AuthorityMarkupPercentageUpdate,
      meta: {
        title: 'TapQuote Admin | Authority Markup Percentage Update',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: '/admin/company-category',
      name: 'CompanyCategory',
      component: CompanyCategory,
      meta: {
        title: 'TapQuote Admin | Company Categories',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: '/admin/company-category/new',
      name: 'CompanyCategoryCreate',
      component: CompanyCategoryCreate,
      meta: {
        title: 'TapQuote Admin | Create Company Category',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: '/admin/company-category/:companyCategoryId/edit',
      name: 'CompanyCategoryEdit',
      component: CompanyCategoryUpdate,
      meta: {
        title: 'TapQuote Admin | Edit Company Category',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: '/admin/transaction',
      name: 'Transaction',
      component: Transaction,
      meta: {
        title: 'TapQuote Admin | Transactions',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: '/admin/transaction/new',
      name: 'TransactionCreate',
      component: TransactionCreate,
      meta: {
        title: 'TapQuote Admin | Create Transaction',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: '/admin/transaction/:transactionId/edit',
      name: 'TransactionEdit',
      component: TransactionUpdate,
      meta: {
        title: 'TapQuote Admin | Edit Transaction',
        authorities: [Authority.GROUP_ADMIN],
      },
    },
    {
      path: '/admin/transaction/:transactionId/view',
      name: 'TransactionView',
      component: TransactionDetails,
      meta: {
        title: 'TapQuote Admin | View Transaction',
        authorities: [Authority.GROUP_ADMIN],
      },
    },

    // jhipster-needle-add-entity-to-router - JHipster will add entities to the router here
  ],
};
