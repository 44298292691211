import { Authority } from '@/shared/security/authority';

const UserQuotationListPage = () => import('@/user/quotation/index.vue');
const UserCompanyProfilePage = () => import('@/user/company-profile/index.vue');
const UserProfilePage = () => import('@/user/profile/profile.vue');
const UserUserManagementPage = () => import('@/user/user-management/index.vue');
const UserNewQuotationPage = () => import('@/user/quotation/create-page.vue');
const UserPaperTypeListPage = () => import('@/user/paper-type/index.vue');
const UserPaperTypeNewPage = () => import('@/user/paper-type/create.vue');
const UserPaperTypeEditPage = () => import('@/user/paper-type/create.vue');
const UserPlateColorListPage = () => import('@/user/plate-color/index.vue');
const UserPlateColorCreatePage = () => import('@/user/plate-color/create.vue');
const UserPlateColorEditPage = () => import('@/user/plate-color/create.vue');
const UserFinishingPropertyIndexPage = () => import('@/user/finishing-property/index.vue');
const UserFinishingPropertyCreatePage = () => import('@/user/finishing-property/create.vue');
const UserQuotationPrintPreviewPage = () => import('@/user/quotation/print-preview.vue');
const UserMachineIndexPage = () => import('@/user/machine/index.vue');
const UserMachineCreatePage = () => import('@/user/machine/create.vue');
const UserMachineEditPage = () => import('@/user/machine/create.vue');
const JobsheetPreviewPage = () => import('@/user/quotation/jobsheet-preview.vue');
const UserPaperCategoryIndexPage = () => import('@/user/paper-category/index.vue');
const UserNewPaperCategoryPage = () => import('@/user/paper-category/create.vue');
const UserEditPaperCategoryPage = () => import('@/user/paper-category/create.vue');
const UserPaperGrammageIndexPage = () => import('@/user/paper-grammage/index.vue');
const UserPaperGrammageCreatePage = () => import('@/user/paper-grammage/create.vue');
const UserPaperGrammageEditPage = () => import('@/user/paper-grammage/create.vue');
const UserAgentsIndexPage = () => import('@/user/agent/index.vue');
const UserReportingPage = () => import('@/user/reporting/index.vue');
const UserCustomerIndexPage = () => import('@/user/customer/index.vue');
const UserCustomerCreatePage = () => import('@/user/customer/create.vue');
const UserCustomerEditPage = () => import('@/user/customer/create.vue');

export default [
  {
    path: '/user/quotation',
    name: 'Quotation',
    component: UserQuotationListPage,
    meta: {
      title: 'TapQuote | Quotation List',
      authorities: [Authority.GROUP_USER],
    },
  },
  {
    path: '/user/quotation/new',
    name: 'New User Quotation',
    component: UserNewQuotationPage,
    meta: {
      title: 'TapQuote | New Quotation',
      authorities: [Authority.GROUP_USER],
    },
  },
  {
    path: '/user/quotation/:code',
    name: 'Quotation Details',
    component: UserNewQuotationPage,
    meta: {
      title: 'TapQuote | Quotation Details',
      authorities: [Authority.GROUP_USER],
    },
  },
  {
    path: '/user/quotation/:code/print-preview',
    name: 'Quotation Details Print Preview',
    component: UserQuotationPrintPreviewPage,
    meta: {
      title: 'TapQuote | Print Preview',
      authorities: [Authority.GROUP_USER],
    },
  },
  {
    path: '/user/quotation/:code/jobsheet-preview',
    name: 'Jobsheet Preview',
    component: JobsheetPreviewPage,
    meta: {
      title: 'TapQuote | Job Sheet Print Preview',
      authorities: [Authority.GROUP_USER],
    },
  },
  {
    path: '/user/company',
    name: 'Company Profile',
    component: UserCompanyProfilePage,
    meta: {
      title: 'TapQuote | Company Profile',
      authorities: [Authority.GROUP_USER, Authority.ADMIN],
    },
  },
  {
    path: '/user/agent',
    name: 'Agents',
    component: UserAgentsIndexPage,
    meta: {
      title: 'TapQuote | Agents Management',
      authorities: [Authority.GROUP_USER, Authority.ADMIN],
    },
  },
  {
    path: '/user/profile',
    name: 'User Profile',
    component: UserProfilePage,
    meta: {
      title: 'TapQuote | User Profile',
      authorities: [Authority.GROUP_USER],
    },
  },
  {
    path: '/user/paper-category',
    name: 'User Paper Category',
    component: UserPaperCategoryIndexPage,
    meta: {
      title: 'TapQuote | Paper Categories',
      authorities: [Authority.GROUP_USER, Authority.ADMIN],
    },
  },
  {
    path: '/user/paper-category/new',
    name: 'User New Paper Category',
    component: UserNewPaperCategoryPage,
    meta: {
      title: 'TapQuote | New Paper Category',
      authorities: [Authority.GROUP_USER, Authority.ADMIN],
    },
  },
  {
    path: '/user/paper-category/:paperCategoryId/edit',
    name: 'User Edit Paper Category',
    component: UserEditPaperCategoryPage,
    meta: { authorities: [Authority.GROUP_USER, Authority.ADMIN] },
  },
  {
    path: '/user/paper-grammage',
    name: 'User Paper Grammage Index Page',
    component: UserPaperGrammageIndexPage,
    meta: {
      title: 'TapQuote | Paper Grammages',
      authorities: [Authority.GROUP_USER, Authority.ADMIN],
    },
  },
  {
    path: '/user/paper-grammage/new',
    name: 'User Paper Grammage Create Page',
    component: UserPaperGrammageCreatePage,
    meta: {
      title: 'TapQuote | CreatePaper Grammage',
      authorities: [Authority.GROUP_USER, Authority.ADMIN],
    },
  },
  {
    path: '/user/paper-grammage/:paperGrammageId/edit',
    name: 'User Paper Grammage Edit Paper Grammage',
    component: UserPaperGrammageEditPage,
    meta: {
      title: 'TapQuote | Edit Paper Grammage',
      authorities: [Authority.GROUP_USER, Authority.ADMIN],
    },
  },
  {
    path: '/user/paper-type',
    name: 'Paper Type',
    component: UserPaperTypeListPage,
    meta: {
      title: 'TapQuote | Paper Types',
      authorities: [Authority.GROUP_USER, Authority.ADMIN],
    },
  },
  {
    path: '/user/paper-type/new',
    name: 'New Paper Type',
    component: UserPaperTypeNewPage,
    meta: {
      title: 'TapQuote | New Paper Type',
      authorities: [Authority.GROUP_USER, Authority.ADMIN],
    },
  },
  {
    path: '/user/paper-type/:paperTypeId/edit',
    name: 'Edit Paper Type',
    component: UserPaperTypeEditPage,
    meta: {
      title: 'TapQuote | Edit Paper Type',
      authorities: [Authority.GROUP_USER, Authority.ADMIN],
    },
  },
  {
    path: '/user/plate-color',
    name: 'User Plate Colors',
    component: UserPlateColorListPage,
    meta: {
      title: 'TapQuote | Plate Colors',
      authorities: [Authority.GROUP_USER, Authority.ADMIN],
    },
  },
  {
    path: '/user/plate-color/:plateColorId/edit',
    name: 'User Plate Color Edit',
    component: UserPlateColorEditPage,
    meta: {
      title: 'TapQuote | Edit Plate Color',
      authorities: [Authority.GROUP_USER, Authority.ADMIN],
    },
  },
  {
    path: '/user/plate-color/new',
    name: 'User Plate Color Create',
    component: UserPlateColorCreatePage,
    meta: {
      title: 'TapQuote | New Plate Color',
      authorities: [Authority.GROUP_USER, Authority.ADMIN],
    },
  },
  {
    path: '/user/machine',
    name: 'User Machine',
    component: UserMachineIndexPage,
    meta: {
      title: 'TapQuote | Machines',
      authorities: [Authority.GROUP_USER, Authority.ADMIN],
    },
  },
  {
    path: '/user/machine/:machineId/edit',
    name: 'Edit User Machine',
    component: UserMachineEditPage,
    meta: {
      title: 'TapQuote | Edit Machine',
      authorities: [Authority.GROUP_USER, Authority.ADMIN],
    },
  },
  {
    path: '/user/machine/new',
    name: 'User Create Machine',
    component: UserMachineCreatePage,
    meta: {
      title: 'TapQuote | Create Machine',
      authorities: [Authority.GROUP_USER, Authority.ADMIN],
    },
  },
  {
    path: '/user/finishing-property',
    name: 'User Finishing Properties',
    component: UserFinishingPropertyIndexPage,
    meta: {
      title: 'TapQuote | Finishing Properties',
      authorities: [Authority.GROUP_USER, Authority.ADMIN],
    },
  },
  {
    path: '/user/finishing-property/new',
    name: 'User Create Finishing Property',
    component: UserFinishingPropertyCreatePage,
    meta: {
      title: 'TapQuote | Create Finishing Property',
      authorities: [Authority.GROUP_USER, Authority.ADMIN],
    },
  },
  {
    path: '/user/reporting',
    name: 'User Reporting',
    component: UserReportingPage,
    meta: {
      title: 'TapQuote | Reporting',
      authorities: [Authority.GROUP_USER, Authority.ADMIN],
    },
  },
  {
    path: '/user/customer',
    name: 'User Customer',
    component: UserCustomerIndexPage,
    meta: {
      title: 'TapQuote | Customers',
      authorities: [Authority.GROUP_USER, Authority.ADMIN],
    },
  },
  {
    path: '/user/customer/new',
    name: 'User Create Customer',
    component: UserCustomerCreatePage,
    meta: {
      title: 'TapQuote | Create Customer',
      authorities: [Authority.GROUP_USER, Authority.ADMIN],
    },
  },
  {
    path: '/user/customer/:customerId/edit',
    name: 'User Edit Customer',
    component: UserCustomerEditPage,
    meta: {
      title: 'TapQuote | Edit Customer',
      authorities: [Authority.GROUP_USER, Authority.ADMIN],
    },
  },
];
