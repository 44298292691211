import { IAuthority } from '@/shared/model/authority.model';
import buildPaginationQueryOpts from '@/shared/sort/sorts';
import axios from 'axios';

const baseApiUrl = 'api/authorities';

export default class AuthorityService {
  public retrieve(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public create(entity: IAuthority): Promise<IAuthority> {
    return new Promise<IAuthority>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public update(entity: IAuthority): Promise<any> {
    return null;
  }
}
