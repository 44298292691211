import axios from 'axios';

const baseApiUrl = '/public/api/payment';

export default class PaymentService {
  // create a agent signup payment link using the backend api. API will need an email as an argument
  public createAgentSignupPaymentLink(email: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/agent-signup`, {
          email: email,
        })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
