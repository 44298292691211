import axios from 'axios';

import buildPaginationQueryOpts from '@/shared/sort/sorts';

import { IBleedPreset } from '@/shared/model/bleed-preset.model';

const baseApiUrl = 'api/bleed-presets';

const baseUserApiUrl = 'api/bleed-preset-by-user';

export default class BleedPresetService {
  public find(id: number): Promise<IBleedPreset> {
    return new Promise<IBleedPreset>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public findOneByUser(): Promise<IBleedPreset> {
    return new Promise<IBleedPreset>((resolve, reject) => {
      axios
        .get(`${baseUserApiUrl}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieve(paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl + `?${buildPaginationQueryOpts(paginationQuery)}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public delete(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public create(entity: IBleedPreset): Promise<IBleedPreset> {
    return new Promise<IBleedPreset>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public update(entity: IBleedPreset): Promise<IBleedPreset> {
    return new Promise<IBleedPreset>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public partialUpdate(entity: IBleedPreset): Promise<IBleedPreset> {
    return new Promise<IBleedPreset>((resolve, reject) => {
      axios
        .patch(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
