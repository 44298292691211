import { Module } from 'vuex';

export interface AgentSignupStore {
  login: string;
}

export const defaultAgentSignUpStore: AgentSignupStore = {
  login: '',
};

export const agentSignupStore: Module<AgentSignupStore, any> = {
  state: {
    ...defaultAgentSignUpStore,
  },

  getters: {
    agentSignupLogin: state => state.login,
  },

  mutations: {
    agentSignupLogin(state, login) {
      state.login = login;
    },
  },
};
