import axios from 'axios';

const baseApiUrl = 'api/company-finishing-properties';

const finishingPropertiesByCompanyUrl = 'api/company-finishing-property';

import buildPaginationQueryOpts from '@/shared/sort/sorts';
import { ICompanyFinishingProperty } from './model/company-finishing-property.model';

export default class CompanyFinishingPropertyService {
  public deleteByCompany(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${finishingPropertiesByCompanyUrl}/${id}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public findByCompanyAndFinishingPropertySlug(slug: string): Promise<ICompanyFinishingProperty> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(finishingPropertiesByCompanyUrl + `/slug/${slug}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveByCompany(paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(finishingPropertiesByCompanyUrl + `?${buildPaginationQueryOpts(paginationQuery)}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveAllByCompany(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(finishingPropertiesByCompanyUrl + `?unpaged=true`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public createByCompany(entity: ICompanyFinishingProperty): Promise<ICompanyFinishingProperty> {
    return new Promise<ICompanyFinishingProperty>((resolve, reject) => {
      axios
        .post(`${finishingPropertiesByCompanyUrl}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public updateByCompany(entity: ICompanyFinishingProperty): Promise<ICompanyFinishingProperty> {
    return new Promise<ICompanyFinishingProperty>((resolve, reject) => {
      axios
        .put(`${finishingPropertiesByCompanyUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
