// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.common with an alias.
import Vue from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { setupAxiosInterceptors } from '@/shared/config/axios-interceptor';

import App from './app.vue';
import Vue2Filters from 'vue2-filters';
import { ToastPlugin } from 'bootstrap-vue';
import router from './router';
import * as config from './shared/config/config';
import * as bootstrapVueConfig from './shared/config/config-bootstrap-vue';
import JhiItemCountComponent from './shared/jhi-item-count.vue';
import JhiSortIndicatorComponent from './shared/sort/jhi-sort-indicator.vue';
import InfiniteLoading from 'vue-infinite-loading';
import HealthService from './admin/health/health.service';
import MetricsService from './admin/metrics/metrics.service';
import LogsService from './admin/logs/logs.service';
import ConfigurationService from '@/admin/configuration/configuration.service';
import ActivateService from './account/activate/activate.service';
import RegisterService from './account/register/register.service';
import UserManagementService from './admin/user-management/user-management.service';
import LoginService from './account/login.service';
import AccountService from './account/account.service';
import AlertService from './shared/alert/alert.service';

import '../content/scss/global.scss';
import '../content/scss/vendor.scss';
import TranslationService from '@/locale/translation.service';
import PaperTypeService from './entities/paper-type/paper-type.service';
import PaperCategoryService from './entities/paper-category/paper-category.service';
import PaperGrammageService from './entities/paper-grammage/paper-grammage.service';
import PlateColorService from './entities/plate-color/plate-color.service';
import QuotationUiService from './user/quotation/quotationui.service';
import UserManagementUiService from './user/user-management/usermanagementui.service';
import QuoteService from './entities/quote/quote.service';
import CalculationService from './user/quotation/calculation.service';
import FinishingCalculationService from './user/quotation/finishing-calculation.service';
import FinishingPropertyService from './entities/finishing-property/finishing-property.service';
import ProductService from './entities/product/product.service';
import LineItemService from './entities/line-item/line-item.service';
import UserCompanyService from './entities/user-company/user-company.service';
import CompanyService from './entities/company/company.service';
import MachineService from './entities/machine/machine.service';
import SubscribtionService from './entities/subscribtion/subscribtion.service';
import UserService from './entities/user/user.service';
import SubscribtionFeatureService from './entities/subscribtion-feature/subscribtion-feature.service';
import SubscribtionPackageService from './entities/subscribtion-package/subscribtion-package.service';
import QuotaService from './entities/quota/quota.service';
import FileService from './shared/file.service';
import ProductCategoryService from './entities/product-category/product-category.service';
import CompanyFinishingPropertyService from './shared/company-finishingproperty.service';
import FinishingPropertyUiService from './user/finishing-property/finishing-propertyui.service';
import BleedPresetService from './entities/bleed-preset/bleed-preset.service';
import AuthorityService from './admin/authority/authority.service';
import AuthorityMarkupPercentageService from './entities/authority-markup-percentage/authority-markup-percentage.service';
import CompanyCategoryService from './entities/company-category/company-category.service';
import PaymentService from './shared/service/payment.service';
import TransactionService from './entities/transaction/transaction.service';
import { CustomerService } from './shared/service/customer.service';
/* tslint:disable */

// jhipster-needle-add-entity-service-to-main-import - JHipster will import entities services here

/* tslint:enable */
Vue.config.productionTip = false;
config.initVueApp(Vue);
config.initFortAwesome(Vue);
bootstrapVueConfig.initBootstrapVue(Vue);
Vue.use(require('vue-shortkey'));
Vue.use(Vue2Filters);
Vue.use(ToastPlugin);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('jhi-item-count', JhiItemCountComponent);
Vue.component('jhi-sort-indicator', JhiSortIndicatorComponent);
Vue.component('infinite-loading', InfiniteLoading);
const i18n = config.initI18N(Vue);
const store = config.initVueXStore(Vue);

const translationService = new TranslationService(store, i18n);
const loginService = new LoginService();
const accountService = new AccountService(store, translationService, router);
const paperTypeService = new PaperTypeService();
const paperCategoryService = new PaperCategoryService();
const paperGrammageService = new PaperGrammageService();
const plateColorService = new PlateColorService();
const quotationUiService = new QuotationUiService();
const quoteService = new QuoteService();
const calculationService = new CalculationService();
const finishingCalculationService = new FinishingCalculationService();
const finishingPropertyService = new FinishingPropertyService();
const companyFinishingPropertyService = new CompanyFinishingPropertyService();
const productService = new ProductService();
const lineItemService = new LineItemService();
const userCompanyService = new UserCompanyService();
const userManagementUiService = new UserManagementUiService();
const companyService = new CompanyService();
const machineService = new MachineService();
const userService = new UserService();
const subscribtionService = new SubscribtionService();
const subscribtionFeatureService = new SubscribtionFeatureService();
const subscriptionPackageService = new SubscribtionPackageService();
const quotaService = new QuotaService();
const fileService = new FileService();
const productCategoryService = new ProductCategoryService();
const bleedPresetService = new BleedPresetService();
const finishingPropertyUiService = new FinishingPropertyUiService();
const authorityService = new AuthorityService();
const userManagementService = new UserManagementService();
const authorityMarkupPercentageService = new AuthorityMarkupPercentageService();
const companyCategoryService = new CompanyCategoryService();
const paymentService = new PaymentService();
const transactionService = new TransactionService();
const customerService = new CustomerService();

router.beforeEach(async (to, from, next) => {
  if (!to.matched.length) {
    next('/not-found');
  } else if (to.meta && to.meta.authorities && to.meta.authorities.length > 0) {
    accountService.hasAnyAuthorityAndCheckAuth(to.meta.authorities).then(value => {
      if (!value) {
        sessionStorage.setItem('requested-url', to.fullPath);
        next('/forbidden');
      } else {
        next();
      }
    });
  } else {
    // no authorities, so just proceed
    next();
  }
});

/* tslint:disable */
const vue = new Vue({
  el: '#app',
  components: { App },
  template: '<App/>',
  router,
  provide: {
    loginService: () => loginService,
    activateService: () => new ActivateService(),
    registerService: () => new RegisterService(),
    userManagementService: () => new UserManagementService(),
    healthService: () => new HealthService(),
    configurationService: () => new ConfigurationService(),
    logsService: () => new LogsService(),
    metricsService: () => new MetricsService(),

    translationService: () => translationService,
    // jhipster-needle-add-entity-service-to-main - JHipster will import entities services here
    accountService: () => accountService,
    paperTypeService: () => paperTypeService,
    paperCategoryService: () => paperCategoryService,
    paperGrammageService: () => paperGrammageService,
    plateColorService: () => plateColorService,
    quotationUiService: () => quotationUiService,

    calculationService: () => calculationService,
    finishingCalculationService: () => finishingCalculationService,
    finishingPropertyService: () => finishingPropertyService,
    companyFinishingPropertyService: () => companyFinishingPropertyService,
    quoteService: () => quoteService,
    productService: () => productService,
    lineItemService: () => lineItemService,
    userCompanyService: () => userCompanyService,
    userManagementUiService: () => userManagementUiService,
    companyService: () => companyService,
    machineService: () => machineService,
    userService: () => userService,
    subscribtionService: () => subscribtionService,
    subscribtionFeatureService: () => subscribtionFeatureService,
    subscribtionPackageService: () => subscriptionPackageService,
    productCategoryService: () => productCategoryService,
    quotaService: () => quotaService,
    fileService: () => fileService,
    bleedPresetService: () => bleedPresetService,
    authorityService: () => authorityService,
    finishingPropertyUiService: () => finishingPropertyUiService,
    alertService: () => new AlertService(),
    authorityMarkupPercentageService: () => authorityMarkupPercentageService,
    companyCategoryService: () => companyCategoryService,
    paymentService: () => paymentService,
    transactionService: () => transactionService,
    customerService: () => customerService,
  },
  i18n,
  store,
});

setupAxiosInterceptors(
  error => {
    const url = error.response?.config?.url;
    const status = error.status || error.response.status;
    if (status === 401) {
      // Store logged out state.
      store.commit('clearSubPackageAndFeatures');
      store.commit('logout');
      if (!url.endsWith('api/account') && !url.endsWith('api/authenticate')) {
        // Ask for a new authentication
        loginService.openLogin(vue);
        return;
      }
    }
    console.log('Unauthorized!');
    return Promise.reject(error);
  },
  error => {
    console.log('Server error!');
    return Promise.reject(error);
  }
);
