var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.ribbonEnabled
    ? _c("div", { staticClass: "ribbon" }, [
        _c(
          "a",
          {
            attrs: { href: "" },
            domProps: {
              textContent: _vm._s(_vm.$t("global.ribbon." + _vm.ribbonEnv)),
            },
          },
          [_vm._v(_vm._s(_vm.ribbonEnv))]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }