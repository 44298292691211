export default class FinishingPropertyUiService {
  openDeleteOverrideModal(instance: Vue) {
    instance.$emit('bv::show::modal', 'deleteOverrideModal');
  }

  closeDeleteOverrideModal(instance: Vue) {
    instance.$emit('bv::hide::modal', 'deleteOverrideModal');
  }

  openUpdateOverrideModal(instance: Vue) {
    instance.$emit('bv::show::modal', 'updateOverrideModal');
  }

  closeUpdateOverrideModal(instance: Vue) {
    instance.$emit('bv::hide::modal', 'updateOverrideModal');
  }

  closeCreateNewOverrideModal(instance: Vue) {
    instance.$emit('bv::hide::modal', 'createOverrideModal');
  }
  openCreateNewOverrideModal(instance: Vue) {
    instance.$emit('bv::show::modal', 'createOverrideModal');
  }
}
