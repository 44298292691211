import { add, multiply, divide, create, all } from 'mathjs';
import FinishingCalculationService from './finishing-calculation.service';
import { PaperGrammage } from '@/shared/model/paper-grammage.model';

const math = create(all);

interface CalculationResult {
  testingpapercount: number;
  testingPaperCost: number;
  actualplate: number;
  actualups: number;
  extraplatecost: number;
  printcost: number;
  totalcost: number;
  diecutPrice: number;
  diecuttotal: number;
}

export default class CalculationService {
  // this function for calculating J, or printcounts
  public printCounts(designAmount: number, upsAmount: number): number {
    const design: number = designAmount;
    const ups: number = upsAmount;
    let result = 0.1;
    let designForLoop = 0;

    for (designForLoop = design; designForLoop > 0; designForLoop--) {
      result = ups / designForLoop;
      if (Number.isInteger(result)) {
        break;
      }
    }

    return designForLoop;
  }

  public findJsOfAnActualUp(totaldesign: number, hs: number): number[] {
    let j: number;
    let k: number = totaldesign;
    const jss: number[] = [];
    while (k !== 0) {
      j = this.printCounts(k, hs);
      k = k - j;
      jss.push(j);
    }
    return jss;
  }

  public findPrintQuantity(quantityPerDesign: number, upAfterCut: number, totaldesign: number, hs: number, cut: number): number[] {
    const js: number[] = this.findJsOfAnActualUp(totaldesign, hs);
    const quantityArr: number[] = [];
    for (let i = 0; i < js.length; i++) {
      let m: number = (quantityPerDesign / (upAfterCut / js[i])) * (upAfterCut / hs);
      if (cut === 1) {
        m = (quantityPerDesign / (upAfterCut / js[i])) * (upAfterCut / hs) * 2;
      }
      quantityArr.push(m);
    }
    return quantityArr;
  }

  public findPrintCost(
    isCard: boolean,
    colorname: string,
    quantityPerDesign: number,
    upAfterCut: number,
    totaldesign: number,
    hs: number,
    printingChargesPer1000_C: number,
    printingBaseCharges_1C: number,
    printingChargesPer1000_4C: number,
    printingBaseCharges_4C: number,
    printingBaseCharges_Card: number,
    printingBaseChargesPer1000_Card: number,
    cut: number
  ): number {
    const quantityArr: number[] = this.findPrintQuantity(quantityPerDesign, upAfterCut, totaldesign, hs, cut);
    const costarr: number[] = [];

    for (let i = 0; i < quantityArr.length; i++) {
      let n: number;
      const m: number = quantityArr[i];

      console.log('findPrintCost m', m);
      console.log('findPrintCost isCard', isCard);

      if (!isCard) {
        if (colorname === '1C' || colorname === '1C+1C') {
          n = (Math.ceil(m / 1000) - 1) * printingChargesPer1000_C + printingBaseCharges_1C;
        } else {
          n = (Math.ceil(m / 1000) - 1) * printingChargesPer1000_4C + printingBaseCharges_4C;
        }
      } else {
        n = (Math.ceil(m / 1000) - 1) * printingBaseChargesPer1000_Card + printingBaseCharges_Card;
      }

      costarr.push(n);
    }

    let sum = 0;
    for (let i = 0; i < costarr.length; i++) {
      sum += costarr[i];
    }

    return sum;
  }

  public findGCD(upsAfterCut: number): number[] {
    const gcds: number[] = [];
    for (let i = 1; i <= upsAfterCut; i++) {
      if (upsAfterCut % i === 0) {
        gcds.push(i);
      }
    }
    return gcds;
  }

  public findActualUpsPerPrintedPaper(upsAfterCut: number): number[] {
    // Find greatest common divisors of upsAfterCut
    const gcds = this.findGCD(upsAfterCut);
    console.log('findActualUpsPerPrintedPaper Greatest common divisors of upsAfterCut:', gcds);

    // Initialize empty array to hold results
    const hs: number[] = [];

    // Loop through GCDs
    for (let i = 0; i < gcds.length; i++) {
      // Calculate a by dividing upsAfterCut by current GCD
      const a = upsAfterCut / gcds[i];

      // Add a to results array
      hs.push(a);
    }

    console.log('findActualUpsPerPrintedPaper hs:', hs);

    // Return results array
    return hs;
  }

  public printUps(totalDesigns: number, upsAmount: number): number {
    let result = 0.1;
    let plateCount = 1;
    let designForLoop = 0;
    let subtractedDesign = 0;

    for (designForLoop = totalDesigns; designForLoop > 0; designForLoop--) {
      result = upsAmount / designForLoop;

      if (Number.isInteger(result)) {
        break;
      }
    }

    subtractedDesign = totalDesigns - designForLoop;

    while (subtractedDesign != 0) {
      const temp: number = subtractedDesign;

      plateCount++;

      for (designForLoop = temp; designForLoop > 0; designForLoop--) {
        result = upsAmount / designForLoop;

        if (Number.isInteger(result)) {
          break;
        }
      }
      subtractedDesign = temp - designForLoop;
    }
    return plateCount;
  }

  public findActualPlates(upsAfterCut: number, totaldesign: number): { actualplate: number; up: number }[] {
    const hs = this.findActualUpsPerPrintedPaper(upsAfterCut);
    const actualplates: { actualplate: number; up: number }[] = [];
    for (let i = 0; i < hs.length; i++) {
      actualplates.push({
        actualplate: this.printUps(totaldesign, hs[i]),
        up: hs[i],
      });
    }
    return actualplates;
  }

  public testingPaperCount(cut: number, ppc: number, actualplate: number, upsFromFormula: number, color: string): number {
    let result = (ppc * actualplate) / upsFromFormula;
    if (color === '4C+4C' || color === '4C+1C' || color === '4C') {
      if (cut === 1) {
        result = (result / 2) * 4;
      } else {
        result = result * 4;
      }
    } else {
      if (cut === 1) {
        result = result / 2;
      }
    }
    return Math.ceil(result);
  }

  public extraPlateCost(
    cut: number,
    colorname: string,
    totalpaperneeded: number,
    actualplate: number,
    actualplatecost: number,
    upsfromformula: number
  ): number {
    let result: number;
    if (cut === 1) {
      if (colorname === '4C+4C' || colorname === '4C+1C' || colorname === '1C+1C') {
        result = math.chain(totalpaperneeded).divide(actualplate).multiply(4).done();
      } else {
        result = math.chain(totalpaperneeded).multiply(actualplate).multiply(2).done();
      }
    } else {
      if (colorname === '4C+4C' || colorname === '4C+1C' || colorname === '1C+1C') {
        result = (totalpaperneeded / actualplate) * 2;
      } else {
        result = totalpaperneeded * actualplate;
      }
    }
    const a: number = Math.ceil(math.divide(result, 64000));
    return (a - 1) * actualplatecost * upsfromformula;
  }

  public upsIf3143(cut: number, actualsUps: number): number {
    if (cut === 1) {
      return Math.ceil(math.divide(actualsUps, 2));
    } else {
      return Math.ceil(actualsUps);
    }
  }

  public calculateCost(
    pricePerPiece: number,
    cut: number,
    ppc: number,
    maxUpsAfterCut: number,
    totalDesign: number,
    paperCost: number,
    platecost: number,
    iscard: boolean,
    colorname: string,
    quantityPerDesign: number,
    paperneeded: number,
    testingPaperCostFinishing: number,
    printingChargesPer1000_C: number,
    printingBaseCharges_1C: number,
    printingChargesPer1000_4C: number,
    printingBaseCharges_4C: number,
    printingBaseCharges_Card: number,
    printingBaseChargesPer1000_Card: number,
    bookInner: boolean,
    diecutArry: any[],
    diecutpriceper1000: number,
    optionDiecut: boolean,
    diecutBlockMinCost: number,
    diecutKnifeMinAmt: number,
    finishingCalculationService: FinishingCalculationService
  ): CalculationResult | undefined {
    console.log('calculateCost calculating actualPlates');
    console.log('calculateCost upsAfterCut', maxUpsAfterCut);
    console.log('calculateCost totalDesign', totalDesign);
    const actualPlates = this.findActualPlates(maxUpsAfterCut, totalDesign);
    const results: CalculationResult[] = [];

    console.log('calculateCost actualPlates', JSON.stringify(actualPlates));
    console.log('calculateCost isCard', iscard);

    for (const actualPlate of actualPlates) {
      const upsFromFormula = maxUpsAfterCut / actualPlate.up;

      const printCost = this.findPrintCost(
        iscard,
        colorname,
        quantityPerDesign,
        maxUpsAfterCut,
        totalDesign,
        actualPlate.up,
        printingChargesPer1000_C,
        printingBaseCharges_1C,
        printingChargesPer1000_4C,
        printingBaseCharges_4C,
        printingBaseCharges_Card,
        printingBaseChargesPer1000_Card,
        cut
      );

      console.log('calculateCost actualPlate', actualPlate);
      console.log('calculateCost up', actualPlate.up);
      console.log('calculateCost printCost', printCost);
      console.log('calculateCost ppc', ppc);

      let paperCount = this.testingPaperCount(cut, ppc, actualPlate.actualplate, upsFromFormula, colorname);
      if (bookInner === true) {
        paperCount = Math.ceil(paperCount / 2);
      }

      const testingPaperCost = paperCount * pricePerPiece;
      const actualPlateCost = actualPlate.actualplate * platecost;
      const totalPaperNeeded = paperCount + paperneeded;

      console.log('calculateCost bookInner', bookInner);
      console.log('calculateCost upsFromFormula', actualPlate.up);
      console.log('calculateCost paperCount', paperCount);

      const extraPlateCost = this.extraPlateCost(
        cut,
        colorname,
        totalPaperNeeded,
        actualPlate.actualplate,
        actualPlateCost,
        actualPlate.up
      );

      console.log('calculateCost testingPaperCost', testingPaperCost);
      console.log('calculateCost extraPlateCost', extraPlateCost);
      console.log('calculateCost paperCost', paperCost);
      console.log('calculateCost testingPaperCostFinishing', testingPaperCostFinishing);
      console.log('calculateCost actualPlateCost', actualPlateCost);

      const result = testingPaperCost + printCost + paperCost + actualPlateCost + extraPlateCost + testingPaperCostFinishing;

      const upsIf3143R = this.upsIf3143(cut, actualPlate.up); // supposedly this variable was supposed to be used in the plates needed calculation but didnt

      let p100 = 0;

      let b61 = 0;
      let o100 = 0;
      if (optionDiecut) {
        for (const diecutItem of diecutArry) {
          const diecutWidth = diecutItem.width;
          const diecutLength = diecutItem.length;
          const diecutAmount = diecutItem.amount;
          console.log('calculateCost diecutItem', diecutItem);
          const diecutPriceFinishing = finishingCalculationService.calculateDiecutBlock(
            optionDiecut,
            diecutItem.width,
            diecutItem.length,
            diecutBlockMinCost,
            diecutItem.knives,
            diecutKnifeMinAmt
          );
          b61 += math.add(diecutWidth, diecutLength);
          if (b61 !== 0) {
            o100 += diecutPriceFinishing * upsIf3143R;
            p100 += Math.ceil(diecutAmount / actualPlate.up / 1000) * diecutpriceper1000;
          }
        }
      }

      console.log('calculateCost b61', b61);
      console.log('calculateCost o100', o100);
      console.log('calculateCost p100', p100);
      console.log('calculateCost result', result);
      const diecutPrice = o100 + p100;
      console.log('calculateCost diecutPrice', diecutPrice);
      const diecutTotal = result + diecutPrice;
      console.log('calculateCost diecutTotal', diecutTotal);

      results.push({
        testingpapercount: paperCount,
        testingPaperCost: testingPaperCost,
        actualplate: actualPlate.actualplate,
        actualups: actualPlate.up,
        extraplatecost: extraPlateCost,
        printcost: printCost,
        totalcost: result,
        diecutPrice: diecutPrice,
        diecuttotal: diecutTotal,
      });
    }

    if (results.length === 0) {
      return undefined;
    }

    return results.reduce((minResult, current) => {
      if (!minResult || current.diecuttotal < minResult.diecuttotal) {
        return current;
      }
      return minResult;
    });
  }

  public findPaperNeeded(colorname: string, quantityPerDesign: number, totalDesign: number, upsAfterCut: number, cut: number): number {
    if (cut === 1) {
      switch (colorname) {
        case '4C+4C':
        case '4C+1C':
        case '1C+1C':
          return Math.ceil(math.chain(quantityPerDesign).multiply(totalDesign).divide(upsAfterCut).divide(2).divide(2).done());
        case '1C':
        case '4C':
          return Math.ceil(math.chain(quantityPerDesign).multiply(totalDesign).divide(upsAfterCut).divide(2).done());
        default:
          // handle other cases or throw an error
          return undefined;
      }
    } else {
      switch (colorname) {
        case '4C+4C':
        case '4C+1C':
        case '1C+1C':
          return Math.ceil(math.chain(quantityPerDesign).multiply(totalDesign).divide(upsAfterCut).divide(2).done());
        case '1C':
        case '4C':
          return Math.ceil(math.chain(quantityPerDesign).multiply(totalDesign).divide(upsAfterCut).done());
        default:
          // handle other cases or throw an error
          return undefined;
      }
    }
  }

  public calcVerticalUps(
    width: number,
    lengthAfterCut: number,
    unprintableAreaNumber: number,
    finalWidth: number,
    finalLength: number,
    cut: number
  ): string {
    console.log('calcVerticalUps width', width);
    console.log('calcVerticalUps lengthAfterCut', lengthAfterCut);
    console.log('calcVerticalUps unprintableAreaNumber', unprintableAreaNumber);
    console.log('calcVerticalUps finalWidth', finalWidth);
    console.log('calcVerticalUps finalLength', finalLength);
    console.log('calcVerticalUps cut', cut);

    const x: number =
      Math.floor((width - 2 * unprintableAreaNumber) / finalWidth) * Math.floor((lengthAfterCut - 2 * unprintableAreaNumber) / finalLength);
    const y: number =
      Math.floor((width - 2 * unprintableAreaNumber) / finalLength) * Math.floor((lengthAfterCut - 2 * unprintableAreaNumber) / finalWidth);
    let result: string;
    const y1: number = Math.floor((lengthAfterCut - 2 * unprintableAreaNumber) / finalLength);
    const y2: number = Math.floor((lengthAfterCut - 2 * unprintableAreaNumber) / finalWidth);

    if (cut === 1) {
      result = x > y ? `${2 * y1} x ${finalLength}mm` : `${2 * y2} x ${finalWidth}mm`;
    } else {
      result = x > y ? `${y1} x ${finalLength}mm` : `${y2} x ${finalWidth}mm`;
    }
    console.log('calcVerticalUps y', y);
    console.log('calcVerticalUps y1', y1);
    console.log('calcVerticalUps y2', y2);
    console.log('calcVerticalUps result', result);
    return result;
  }

  public calcDigitalVerticalUps(
    width: number,
    lengthAfterCut: number,
    unprintableAreaNumber: number,
    finalWidth: number,
    finalLength: number,
    cut: number
  ): string {
    console.log('calcVerticalUps width', width);
    console.log('calcVerticalUps lengthAfterCut', lengthAfterCut);
    console.log('calcVerticalUps unprintableAreaNumber', unprintableAreaNumber);
    console.log('calcVerticalUps finalWidth', finalWidth);
    console.log('calcVerticalUps finalLength', finalLength);
    console.log('calcVerticalUps cut', cut);

    const x: number =
      Math.floor((width - 2 * unprintableAreaNumber) / finalWidth) * Math.floor((lengthAfterCut - 2 * unprintableAreaNumber) / finalLength);
    const y: number =
      Math.floor((width - 2 * unprintableAreaNumber) / finalLength) * Math.floor((lengthAfterCut - 2 * unprintableAreaNumber) / finalWidth);

    const y1: number = Math.floor((lengthAfterCut - 2 * unprintableAreaNumber) / finalLength);
    const y2: number = Math.floor((lengthAfterCut - 2 * unprintableAreaNumber) / finalWidth);

    let result;
    if (x > y) {
      result = `${y1} x ${finalLength}mm`;
    } else {
      result = `${y2} x ${finalWidth}mm`;
    }

    console.log('calcVerticalUps y', y);
    console.log('calcVerticalUps y1', y1);
    console.log('calcVerticalUps y2', y2);
    console.log('calcVerticalUps result', result);
    return result;
  }

  public calcDigitalHorizontalUps(
    width: number,
    lengthAfterCut: number,
    unprintableAreaNumber: number,
    finalWidth: number,
    finalLength: number
  ): string {
    console.log('calcHorizontalUps width', width);
    console.log('calcHorizontalUps lengthAfterCut', lengthAfterCut);
    console.log('calcHorizontalUps unprintableAreaNumber', unprintableAreaNumber);
    console.log('calcHorizontalUps finalWidth', finalWidth);
    console.log('calcHorizontalUps finalLength', finalLength);
    const x: number =
      Math.floor((width - 2 * unprintableAreaNumber) / finalWidth) * Math.floor((lengthAfterCut - 2 * unprintableAreaNumber) / finalLength);
    const y: number =
      Math.floor((width - 2 * unprintableAreaNumber) / finalLength) * Math.floor((lengthAfterCut - 2 * unprintableAreaNumber) / finalWidth);
    let result: string;
    const x1: number = Math.floor((width - 2 * unprintableAreaNumber) / finalWidth);
    const x2: number = Math.floor((width - 2 * unprintableAreaNumber) / finalLength);

    console.log('calcHorizontalUps x', x);
    console.log('calcHorizontalUps y', y);
    console.log('calcHorizontalUps x1', x1);
    console.log('calcHorizontalUps x2', x2);

    if (x > y) {
      result = `${x1} x ${finalWidth}mm`;
    } else {
      result = `${x2} x ${finalLength}mm`;
    }

    return result;
  }

  public calcHorizontalUps(
    width: number,
    lengthAfterCut: number,
    unprintableAreaNumber: number,
    finalWidth: number,
    finalLength: number
  ): string {
    console.log('calcHorizontalUps width', width);
    console.log('calcHorizontalUps lengthAfterCut', lengthAfterCut);
    console.log('calcHorizontalUps unprintableAreaNumber', unprintableAreaNumber);
    console.log('calcHorizontalUps finalWidth', finalWidth);
    console.log('calcHorizontalUps finalLength', finalLength);
    const x: number =
      Math.floor((width - 2 * unprintableAreaNumber) / finalWidth) * Math.floor((lengthAfterCut - 2 * unprintableAreaNumber) / finalLength);
    const y: number =
      Math.floor((width - 2 * unprintableAreaNumber) / finalLength) * Math.floor((lengthAfterCut - 2 * unprintableAreaNumber) / finalWidth);
    let result: string;
    const x1: number = Math.floor((width - 2 * unprintableAreaNumber) / finalWidth);
    const x2: number = Math.floor((width - 2 * unprintableAreaNumber) / finalLength);

    console.log('calcHorizontalUps x', x);
    console.log('calcHorizontalUps y', y);
    console.log('calcHorizontalUps x1', x1);
    console.log('calcHorizontalUps x2', x2);

    if (x > y) {
      result = `${x1} x ${finalWidth}mm`;
    } else {
      result = `${x2} x ${finalLength}mm`;
    }

    return result;
  }

  public calculateMaxUps(
    width: number,
    lengthAfterCut: number,
    unprintableArea: number,
    finalWidthWithBleed: number,
    finalLengthWithBleed: number,
    cut: number
  ): number {
    const horizontalUps: number =
      Math.floor((width - 2 * unprintableArea) / finalWidthWithBleed) *
      Math.floor((lengthAfterCut - 2 * unprintableArea) / finalLengthWithBleed);
    const verticalUps: number =
      Math.floor((width - 2 * unprintableArea) / finalLengthWithBleed) *
      Math.floor((lengthAfterCut - 2 * unprintableArea) / finalWidthWithBleed);
    let result: number;

    if (cut === 1) {
      result = horizontalUps > verticalUps ? 2 * horizontalUps : 2 * verticalUps;
    } else {
      result = horizontalUps > verticalUps ? horizontalUps : verticalUps;
    }

    return result;
  }

  public g63Cal(D63: number, B63: number): boolean {
    return Number.isInteger(D63 / B63) || D63 / B63 === 2 || B63 / D63 === 2;
  }

  public h63Cal(F63: number, B63: number): boolean {
    return F63 % B63 === 0 || F63 / B63 === 2 || B63 / F63 === 2;
  }

  public e63Cal(cut: number, width: number, ua: number, finalWidth: number, length: number, finalLength: number): number {
    if (cut == 1) {
      return (
        Math.min(
          Math.trunc((width - ua * 2) / finalWidth) * Math.trunc((length - ua * 2) / finalLength),
          Math.trunc((width - ua * 2) / finalLength) * Math.trunc((length - ua * 2) / finalWidth)
        ) * 2
      );
    } else {
      return Math.min(
        Math.trunc((width - ua * 2) / finalWidth) * Math.trunc((length - ua * 2) / finalLength),
        Math.trunc((width - ua * 2) / finalLength) * Math.trunc((length - ua * 2) / finalWidth)
      );
    }
  }

  public maxUpAfterCutCal(
    cut: number,
    width: number,
    ua: number,
    finalWidth: number,
    length: number,
    finalLength: number,
    maxUpsPerPaper: number,
    bookUps: number
  ): number {
    let D63: number;
    if (cut == 1) {
      D63 = math.divide(maxUpsPerPaper, 2);
    } else {
      D63 = maxUpsPerPaper;
    }

    console.log('maxUpAfterCutCal D63', D63);

    const B63 = bookUps;
    console.log('maxUpAfterCutCal B63', B63);
    const G63 = this.g63Cal(D63, B63);
    console.log('maxUpAfterCutCal G63', G63);
    const E63 = this.e63Cal(cut, width, ua, finalWidth, length, finalLength);
    let F63 = E63;
    if (cut == 1) {
      F63 = math.divide(E63, 2);
    }
    const H63 = this.h63Cal(F63, B63);

    console.log('maxUpAfterCutCal H63', H63);

    let finalResult;

    if (G63) {
      finalResult = D63;
    } else if (H63) {
      finalResult = F63;
    } else if (D63 % B63 === 0) {
      finalResult = D63;
    } else if (D63 >= B63) {
      finalResult = B63;
    } else if (D63 < B63 && D63 >= B63 / 2) {
      finalResult = math.divide(B63, 2);
    } else if (D63 < B63 / 2 && D63 >= B63 / 4) {
      finalResult = math.divide(B63, 4);
    } else if (D63 < B63 / 4 && D63 >= B63 / 8) {
      finalResult = math.divide(B63, 8);
    } else if (D63 === 1) {
      finalResult = 1;
    }

    return finalResult;
  }

  // Calculate cut amount
  public verticalCut(cut: number, width: number, length: number, ua: number, finalW: number, finalL: number): number {
    let result: number;
    const x = Math.floor((width - 2 * ua) / finalW);
    const y = Math.floor((length - 2 * ua) / finalL);
    const z = Math.floor((width - 2 * ua) / finalL);
    const t = Math.floor((length - 2 * ua) / finalW);

    if (cut == 1) {
      if (x * y > z * t) {
        result = y * 2;
      } else {
        result = t * 2;
      }
    } else {
      if (x * y > z * t) {
        result = y;
      } else {
        result = t;
      }
    }

    return result;
  }

  public horizontalCut(width: number, length: number, ua: number, finalW: number, finalL: number): number {
    let result: number;
    const x = Math.floor((width - 2 * ua) / finalW);
    const y = Math.floor((length - 2 * ua) / finalL);
    const z = Math.floor((width - 2 * ua) / finalL);
    const t = Math.floor((length - 2 * ua) / finalW);

    if (x * y > z * t) {
      result = x;
    } else {
      result = z;
    }

    return result;
  }

  public cutAmount(
    cut: number,
    width: number,
    length: number,
    ua: number,
    finalW: number,
    finalL: number,
    offsetFixedCut: number,
    offset3143FixedCut: number
  ): number {
    let result: number;
    const vcut = this.verticalCut(cut, width, length, ua, finalW, finalL);
    const hcut = this.horizontalCut(width, length, ua, finalW, finalL);
    const totalcut = vcut + hcut - 2;

    if (cut === 1) {
      result = totalcut + offset3143FixedCut;
    } else {
      result = totalcut + offsetFixedCut;
    }

    return result;
  }

  // DIGITAL PRINT: calculate max ups per paper
  public calcMaxUpPerPaperDigital(
    digitalWidth: number,
    digitalLength: number,
    unprintableArea: number,
    finalWidth: number,
    finalLength: number,
    companyCategory: string
  ): number {
    const horizontalCount = Math.trunc((digitalWidth - unprintableArea * 2) / finalWidth);
    const verticalCount = Math.trunc((digitalLength - unprintableArea * 2) / finalLength);
    const rotatedHorizontalCount = Math.trunc((digitalWidth - unprintableArea * 2) / finalLength);
    const rotatedVerticalCount = Math.trunc((digitalLength - unprintableArea * 2) / finalWidth);

    let maxHorizontal: number;
    let maxVertical: number;
    const normalOrientation = math.multiply(horizontalCount, verticalCount);
    const rotatedOrientation = math.multiply(rotatedHorizontalCount, rotatedVerticalCount);
    if (normalOrientation > rotatedOrientation) {
      maxHorizontal = horizontalCount;
      maxVertical = verticalCount;
    } else {
      maxHorizontal = rotatedHorizontalCount;
      maxVertical = rotatedVerticalCount;
    }
    if (companyCategory === 'digital') {
      return math.multiply(maxHorizontal, maxVertical);
    } else {
      return math.chain(maxHorizontal).multiply(maxVertical).multiply(4).done();
    }
  }

  public calcHorizontalUpsDigital(
    digitalWidth: number,
    digitalLength: number,
    ua: number,
    finalWidth: number,
    finalLength: number
  ): string {
    const x = Math.floor((digitalWidth - ua * 2) / finalWidth);
    const y = Math.floor((digitalLength - ua * 2) / finalLength);
    const z = Math.floor((digitalWidth - ua * 2) / finalLength);
    const t = Math.floor((digitalLength - ua * 2) / digitalWidth);
    let result: string;

    if (x * y > z * t) {
      result = x + ' x ' + finalWidth + 'mm';
    } else {
      result = z + ' x ' + finalLength + 'mm';
    }

    return result;
  }

  public calcVerticalUpsDigital(digitalWidth: number, digitalLength: number, ua: number, finalWidth: number, finalLength: number): string {
    const x = Math.floor((digitalWidth - ua * 2) / finalWidth);
    const y = Math.floor((digitalLength - ua * 2) / finalLength);
    const z = Math.floor((digitalWidth - ua * 2) / finalLength);
    const t = Math.floor((digitalLength - ua * 2) / digitalWidth);
    let result: string;

    if (x * y > z * t) {
      result = y + ' x ' + finalLength + 'mm';
    } else {
      result = t + ' x ' + finalWidth + 'mm';
    }

    return result;
  }

  public calculatePrintCostDigital(colorname: string, paperneeded: number, digitalClickCharge: number, companyCategory: string): number {
    switch (colorname) {
      case '1C':
        if (companyCategory === 'digital') {
          return math.chain(paperneeded).multiply(digitalClickCharge).done();
        } else {
          return math.chain(paperneeded).multiply(4).multiply(digitalClickCharge).done();
        }
      case '4C':
        if (companyCategory === 'digital') {
          return math.chain(paperneeded).multiply(digitalClickCharge).done();
        } else {
          return math.chain(paperneeded).multiply(4).multiply(digitalClickCharge).done();
        }
      case '1C+4C':
        if (companyCategory === 'digital') {
          return math.chain(paperneeded).multiply(digitalClickCharge).multiply(2).done();
        } else {
          return math.chain(paperneeded).multiply(4).multiply(digitalClickCharge).multiply(2).done();
        }
      case '4C+4C':
        if (companyCategory === 'digital') {
          return math.chain(paperneeded).multiply(digitalClickCharge).multiply(2).done();
        } else {
          return math.chain(paperneeded).multiply(4).multiply(digitalClickCharge).multiply(2).done();
        }
      case '4C+1C':
        if (companyCategory === 'digital') {
          return math.chain(paperneeded).multiply(digitalClickCharge).multiply(2).done();
        } else {
          return math.chain(paperneeded).multiply(4).multiply(digitalClickCharge).multiply(2).done();
        }
      case '1C+1C':
        if (companyCategory === 'digital') {
          return math.chain(paperneeded).multiply(digitalClickCharge).multiply(2).done();
        } else {
          return math.chain(paperneeded).multiply(4).multiply(digitalClickCharge).multiply(2).done();
        }
    }
  }

  public checkFinishingOption(
    optionfrontgloss: boolean,
    optionbackgloss: boolean,
    optionfrontmatt: boolean,
    optionbackmatt: boolean,
    optionfrontwaterbased: boolean,
    optionbackwaterbased: boolean,
    optionfrontuv: boolean,
    optionbackuv: boolean,
    optionfrontvarnish: boolean,
    optionbackvarnish: boolean,
    optionfrontspotuv: boolean,
    optionbackspotuv: boolean,
    optionemboss: boolean,
    optiondeboss: boolean,
    optionfronths: boolean,
    optionbackhs: boolean,
    optiondiecut: boolean,
    offsetLamTestingQuantity: number,
    waterBasedTestingQuantity: number,
    uvTestingQuantity: number,
    varnishTestingQuantity: number,
    spotuvTestingQuantity: number,
    embossdebossTestingQuantity: number,
    hotstampingTestingQuantity: number,
    diecutTestingQuantity: number,
    softtouchfront: boolean,
    softtouchback: boolean
  ): number {
    let sum = 0;

    if (optionfrontgloss || optionbackgloss) {
      sum += offsetLamTestingQuantity;
    }
    if (optionfrontmatt || optionbackmatt) {
      sum += offsetLamTestingQuantity;
    }
    if (optionfrontwaterbased || optionbackwaterbased) {
      sum += waterBasedTestingQuantity;
    }
    if (optionfrontuv || optionbackuv) {
      sum += uvTestingQuantity;
    }
    if (optionfrontvarnish || optionbackvarnish) {
      sum += varnishTestingQuantity;
    }
    if (optionfrontspotuv || optionbackspotuv) {
      sum += spotuvTestingQuantity;
    }
    if (softtouchfront || softtouchback) {
      sum += offsetLamTestingQuantity;
    }
    if (optionemboss || optiondeboss) {
      sum += embossdebossTestingQuantity;
    }
    if (optionfronths || optionbackhs) {
      sum += hotstampingTestingQuantity;
    }
    if (optiondiecut) {
      sum += diecutTestingQuantity;
    }

    return sum;
  }

  public CalculateTestPaperForFinishing(
    cut: number,
    optionfrontgloss: boolean,
    optionbackgloss: boolean,
    optionfrontmatt: boolean,
    optionbackmatt: boolean,
    optionfrontwaterbased: boolean,
    optionbackwaterbased: boolean,
    optionfrontuv: boolean,
    optionbackuv: boolean,
    optionfrontvarnish: boolean,
    optionbackvarnish: boolean,
    optionfrontspotuv: boolean,
    optionbackspotuv: boolean,
    optionemboss: boolean,
    optiondeboss: boolean,
    optionfronths: boolean,
    optionbackhs: boolean,
    optiondiecut: boolean,
    offsetLamTestingQuantity: number,
    waterBasedTestingQuantity: number,
    uvTestingQuantity: number,
    varnishTestingQuantity: number,
    spotuvTestingQuantity: number,
    embossdebossTestingQuantity: number,
    hotstampingTestingQuantity: number,
    diecutTestingQuantity: number,
    softtouchfront: boolean,
    softtouchback: boolean
  ): number {
    const sum = this.checkFinishingOption(
      optionfrontgloss,
      optionbackgloss,
      optionfrontmatt,
      optionbackmatt,
      optionfrontwaterbased,
      optionbackwaterbased,
      optionfrontuv,
      optionbackuv,
      optionfrontvarnish,
      optionbackvarnish,
      optionfrontspotuv,
      optionbackspotuv,
      optionemboss,
      optiondeboss,
      optionfronths,
      optionbackhs,
      optiondiecut,
      offsetLamTestingQuantity,
      waterBasedTestingQuantity,
      uvTestingQuantity,
      varnishTestingQuantity,
      spotuvTestingQuantity,
      embossdebossTestingQuantity,
      hotstampingTestingQuantity,
      diecutTestingQuantity,
      softtouchfront,
      softtouchback
    );

    return cut === 1 ? sum / 2 : sum;
  }

  calculateCuttingCost(
    totalPaperNeeded: number,
    perReam: number,
    cuttingPriceBelow10Rim: number,
    cuttingPriceBetween: number,
    cuttingPriceAbove30Rim: number
  ): number {
    const reamsNeeded: number = Math.ceil(math.divide(totalPaperNeeded, perReam));

    if (reamsNeeded > 30) {
      return math.multiply(reamsNeeded, cuttingPriceAbove30Rim);
    } else if (reamsNeeded <= 10) {
      return math.multiply(reamsNeeded, cuttingPriceBelow10Rim);
    } else {
      return math.multiply(reamsNeeded, cuttingPriceBetween);
    }
  }
}
