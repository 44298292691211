var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "footer", attrs: { id: "footer" } }, [
    _c("p", { domProps: { textContent: _vm._s(_vm.$t("footer")) } }, [
      _vm._v("This is your footer"),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }